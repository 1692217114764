import Helpers from '../../../common/utils/helpers';

import ApiConstant from "../../constants/api.constants";
import api from "../api";

// GetEspPastVerifications
const AnomalyDetectionGetWellSummaryService = async (startDate: any, endDate: any) => {
    let post_body = {};
    if (startDate == endDate) {
        post_body = { CustomerIds: [] }
    } else {
        post_body = {
            CustomerIds: [],
            StartTime: `'${startDate}'`,

            EndTime: `'${endDate}'`
        }
    }
    return await api
        .post(ApiConstant.GetESPSummary, post_body)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });


}

const AnomalyDetectionGetWellSubSummaryService = async (CustID: any, AssetID: any, firstOccurance: any, lastOccurance: any) => {
    var isoDateStringLast24HoursStart = "";
    if (firstOccurance == "") {
        firstOccurance = "";
        isoDateStringLast24HoursStart = "";
    } else if (lastOccurance == "subtable") {
        isoDateStringLast24HoursStart = `${firstOccurance}`;
        var minus_one_day = new Date(firstOccurance);
        minus_one_day.setDate(minus_one_day.getDate() - 1);
        var isoDateStringLast24HoursStart = new Date(minus_one_day).toISOString();
        isoDateStringLast24HoursStart = `${isoDateStringLast24HoursStart}`;
    } else if (firstOccurance != null && lastOccurance != null) {
        isoDateStringLast24HoursStart = `${firstOccurance}`;
        firstOccurance = `${lastOccurance}`;
    } else {
        var minus_one_day = new Date(firstOccurance);
        minus_one_day.setDate(minus_one_day.getDate() - 15);
        var isoDateStringLast24HoursStart = new Date(minus_one_day).toISOString();
        firstOccurance = `${firstOccurance}`;
        isoDateStringLast24HoursStart = `${isoDateStringLast24HoursStart}`;
    }

    return await api
        .post(ApiConstant.GetEspPastVerifications, {
            CustomerId: CustID,
            AssetId: [AssetID],
            StartTime: `'${isoDateStringLast24HoursStart}'`,
            EndTime: `'${firstOccurance}'`
        })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

//To add anomaly data - Jerin Monish
const AddAnomalyDetectionGetWellSummaryService = async (Redux_data: any, new_data: any, existing_data: any) => {
    return await api.post(ApiConstant.AddUpdateESPVerification, new_data, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    }).then(function (response: any) {
        let _ReduxData = JSON.parse(JSON.stringify(Redux_data)); //Created A Copy for rewrite object 
        _ReduxData.forEach(function (obj: any) {
            if (obj.customerID == existing_data?.CustomerId && obj.assetId == existing_data?.wellId) {
                console.log(obj)
                obj.assetName = existing_data?.asset_name;
                obj.assetId = existing_data?.wellId;
                obj.customerName = existing_data?.operator_name;
                obj.customerID = existing_data?.CustomerId;
                obj.severity = existing_data?.correct_level;
                obj.firstOccurance = existing_data.survey_start_date ? Helpers.convertDateToUTC(existing_data.survey_start_date, existing_data.survey_start_time) : "";
                obj.lastOccurance = existing_data.survey_end_date ? Helpers.convertDateToUTC(existing_data.survey_end_date, existing_data.survey_end_time) : null;
                obj.reason = existing_data?.reason_anamoly;
                obj.comments = existing_data?.description;
                obj.leak = existing_data?.ai_correct;
            }
        });
        return _ReduxData
    }).catch(function (error) {
        Helpers.ConsoleLogError(error);
    });
}

//To get well data based on customer id - Jerin Monish
const getWellDetailsById = async (id: any) => {
    return await api.post(ApiConstant.GetESPSummary, {
        CustomerIds: [id]
    }).then(function (response: any) {
        return response.data;
    }).catch(function (error) {
        Helpers.ConsoleLogError(error);
    });
}


const GetAICallVHealthIndex = async (assetID: any, CustomerId: any, StartTime: any, TimeFilter: any) => {
    return await api
        .post(ApiConstant.GetAICallVHealthIndex, {
            AssetId: [assetID],
            StartTime: `'${StartTime}'`,
            EndTime: `'${TimeFilter}'`,
            CustomerId: CustomerId
        })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const GetRawFeatures = async (assetID: any, CustomerId: any, StartTime: any, TimeFilter: any) => {
    return await api
        .post(ApiConstant.GetRawFeatures, {
            AssetId: [assetID],
            StartTime: `'${StartTime}'`,
            EndTime: `'${TimeFilter}'`,
            CustomerId: CustomerId
        })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const GetEspVerificationlog = async (CustomerId: any, StartTime: any, EndTime: any, assetID: any) => {

    return await api
        .post(ApiConstant.GetEspVerificationlog, {
            AssetType: "Esp",
            CustomerID: CustomerId,
            AssetId: [assetID],
            DateFilterStartDate: "",
            DateFilterEndDate: "",
            ModelType: "Esp",
            State: "All,Monitor,close,true,close,false"
        })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const DownloadService = async (fileName: any) => {
    return await api
        .get(ApiConstant.DownloadFile + "?fileName=" + fileName, {
            responseType: 'blob',
        })
        .then(function (response: any) {

            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const AnomalyDetectionService = {
    AnomalyDetectionGetWellSummaryService,
    AddAnomalyDetectionGetWellSummaryService,
    AnomalyDetectionGetWellSubSummaryService,
    getWellDetailsById,
    GetAICallVHealthIndex,
    GetRawFeatures,
    GetEspVerificationlog,
    DownloadService
};
export default AnomalyDetectionService;
// react
import { useState, useEffect } from 'react';
// i18n
import { useTranslation } from "react-i18next";
// prime react
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
// formik
import { useFormik } from 'formik';
// YUp
import * as Yup from 'yup';
// react select
import Select from 'react-select';
// moment
import moment from "moment";
import 'moment-timezone';
// services
import AnomalyDetectionService from '../../services/anamolt-detection/anomaly-detection.services';
import TokenService from '../../services/autentication/token.service';

// redux store
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, AppState } from '../../store/esp-ai/store';
import { addAnamolyDetectionStoreData } from '../../store/esp-ai/esp-anomaly-detection.store';
// download file
import fileDownload from 'js-file-download';
import { Tooltip } from 'primereact/tooltip';


const AnamolyVerificationAddEditPopUp = (props: any) => {

  let edit_data = (props?.title == 'Edit') ? props.data : [];
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const AnamolyDetectionData_Redux: any = useSelector((state: AppState) => state.EspAnomalyDetectionStore.AnamolyDetectionStoreData);

  const [surveyStartDate, setSurveyStartDate] = useState<any>("");
  const [surveyEndDate, setSurveyEndDate] = useState<any>("");
  const [surveyMinDate, setSurveyMinDate] = useState<any>("");
  const [survey_start_time, setSurveyStartTime] = useState<any>("");
  const [survey_end_time, setSurveyEndTime] = useState<any>("");
  const [attachmentName, setattachmentName] = useState<any>("");
  const [correctLevel, setCorrectLevel] = useState<any>(null);
  const [EditCorrectLevel, setEditCorrectLevel] = useState<any>(null);
  const [reasonAnamoly, setReasonAnamoly] = useState<any>(null);
  const [operatorName, setOperatorName] = useState<any>(null);
  const [operatorId, setOperatorId] = useState<any>(null);
  const [wellName, setWellName] = useState<any>(null);
  const [wellLoading, setWellLoading] = useState(false);
  const [wellId, setWellId] = useState<any>(null);
  const [visibleForSubmit, setVisibleForSubmit] = useState(false);
  const [filPreview, setFilPreview] = useState<any>(null);
  const [formValues, setformValues] = useState<any>([]);
  const [curUserData, setCurUserData] = useState<any>(null);
  const [assetNameOptions, setAssetNameOptions] = useState<any>([]);
  const [aiYesNo, setAiYesNo] = useState<any>(null);


  const securityLevelOptions = [
    { value: 'normal', label: <span dangerouslySetInnerHTML={{ __html: '<span class="badge rounded bg-success p-1"> </span> Normal' }} /> },
    { value: 'low', label: <span dangerouslySetInnerHTML={{ __html: '<span class="badge rounded bg-yellow p-1"> </span> Level 1' }} /> },
    { value: 'medium', label: <span dangerouslySetInnerHTML={{ __html: '<span class="badge rounded bg-orange p-1"> </span> Level 2' }} /> },
    { value: 'high', label: <span dangerouslySetInnerHTML={{ __html: '<span class="badge rounded bg-danger p-1"> </span> Level 3' }} /> }
  ];
  var resonAnamolyYesOptions: any;
  if (props?.title == 'Edit') {
    resonAnamolyYesOptions = [
      { label: 'Communication issue', value: 'CommIssue' },
      { label: 'Gas lock', value: 'Gaslock' },
      { label: 'Gas slug', value: 'Gasslug' },
      { label: 'Others', value: 'Others' },
      { label: 'Prediction incorrect', value: 'Incorrect' },
      { label: 'Sensor issue', value: 'SensorError' },
      { label: 'Startup/shutdown (planned event)', value: 'Planned' },
      { label: 'Unplanned down event', value: 'Unplanned' },
    ];
  } else {
    resonAnamolyYesOptions = [
      { label: 'Communication issue', value: 'CommIssue' },
      { label: 'Gas lock', value: 'Gaslock' },
      { label: 'Gas slug', value: 'Gasslug' },
      { label: 'Others', value: 'Others' },
      { label: 'Prediction incorrect', value: 'Incorrect' },
      { label: 'Sensor issue', value: 'SensorError' },
      { label: 'Startup/shutdown (planned event)', value: 'Planned' },
      { label: 'Unplanned down event', value: 'Unplanned' },
    ];
    resonAnamolyYesOptions = [
      { label: 'Communication issue', value: 'CommIssue' },
      { label: 'Gas lock', value: 'Gaslock' },
      { label: 'Gas slug', value: 'Gasslug' },
      { label: 'Others', value: 'Others' },
      { label: 'Prediction incorrect', value: 'Incorrect' },
      { label: 'Sensor issue', value: 'SensorError' },
      { label: 'Startup/shutdown (planned event)', value: 'Planned' },
      { label: 'Unplanned down event', value: 'Unplanned' },
    ];
  }


  const resonAnamolyNoOptions = [
    { label: 'Communication issue', value: 'CommIssue' },
    { label: 'Gas lock', value: 'Gaslock' },
    { label: 'Gas slug', value: 'Gasslug' },
    { label: 'Others', value: 'Others' },
    { label: 'Prediction incorrect', value: 'Incorrect' },
    { label: 'Sensor issue', value: 'SensorError' },
    { label: 'Startup/shutdown (planned event)', value: 'Planned' },
    { label: 'Unplanned down event', value: 'Unplanned' },

  ];

  const Icon = () => {
    return <>
      <div style={{ textAlign: "center", width: '30px', height: '30px', background: '#ddf2fc', margin: '0 auto', borderRadius: '25px'/*, padding: '0.7rem 0.7rem'*/ }}>
        <i className="pi pi-check-circle" style={{ color: '#1e97e9', fontSize: '20px' }}></i>
      </div>
    </>
  }

  useEffect(() => {
    setCurUserData(TokenService?.getUser());
    if (edit_data?.customerID) {
      verificationFormik.setValues({
        "operator_name": edit_data?.customerName,
        "well_name": edit_data?.assetId,
        "asset_name": edit_data?.assetName,
        "ai_correct": edit_data?.leak,
        "correct_level": edit_data?.severity,
        "reason_anamoly": (edit_data?.reason == null) ? "" : edit_data?.reason,
        "survey_start_date": edit_data?.firstOccurance ? moment(edit_data?.firstOccurance).local().format('MM/DD/YYYY') : "",
        "survey_end_date": edit_data?.lastOccurance ? moment(edit_data?.lastOccurance).local().format('MM/DD/YYYY') : "",
        "survey_start_time": moment(edit_data?.firstOccurance).local().format('h:mm A'),
        "survey_end_time": moment(edit_data?.lastOccurance).local().format('h:mm A'),
        "description": edit_data?.comments,
      });

      if (edit_data?.firstOccurance != null) {
        setSurveyStartDate(moment(edit_data?.firstOccurance).local().format())
      }

      if (edit_data?.lastOccurance != null) {
        setSurveyEndDate(moment(edit_data?.lastOccurance).local().format())
      }
      if (edit_data?.attachments?.length > 0) {
        if (edit_data?.attachments[0] != "null") {
          setattachmentName(edit_data?.attachments[0]);
          setFilPreview(edit_data?.attachments[0]);
        }
        setSurveyEndDate(moment(edit_data?.lastOccurance).local().format())
      }

      setSurveyStartTime(new Date(moment(edit_data?.firstOccurance).local().format()))
      // moment(Helpers.removeAlphabetsAlone(edit_data?.firstOccurance)).format('h:mm A')
      if (edit_data?.lastOccurance != null) {
        setSurveyEndTime(new Date(moment(edit_data?.lastOccurance).local().format()))
      }


      setWellId(edit_data?.assetId)
      setOperatorId(edit_data?.customerID)
      setAiYesNo(edit_data?.leak)
      if (edit_data?.reason) {
        let obj = resonAnamolyNoOptions.find(o => o.value === edit_data?.reason);
        setReasonAnamoly({ 'value': edit_data?.reason, 'label': obj?.label })
      }

      if (edit_data?.severity == "1" || edit_data?.severity == "low") {
        setCorrectLevel({ 'value': "low", 'label': "Level 1" })
        setEditCorrectLevel({ 'value': "low", 'label': "Level 1" });
      } else if (edit_data?.severity == "2" || edit_data?.severity == "medium") {
        setCorrectLevel({ 'value': "medium", 'label': "Level 2" })
        setEditCorrectLevel({ 'value': "medium", 'label': "Level 2" })
      } else if (edit_data?.severity == "3" || edit_data?.severity == "high") {
        setCorrectLevel({ 'value': "high", 'label': "Level 3" })
        setEditCorrectLevel({ 'value': "high", 'label': "Level 3" })
      } else if (edit_data?.severity == "0" || edit_data?.severity == "normal") {
        setCorrectLevel({ 'value': "normal", 'label': "Normal" })
        setEditCorrectLevel({ 'value': "normal", 'label': "Normal" })
      } else if (edit_data?.severity == null) {
        setCorrectLevel([])
        setEditCorrectLevel([])
      } else if (edit_data?.anomaly == "NORMAL") {
        setCorrectLevel({ 'value': "normal", 'label': "Normal" })
        setEditCorrectLevel({ 'value': "normal", 'label': "Normal" })
      }
      if (edit_data?.leak == true) {
        var tempRes = resonAnamolyYesOptions?.filter((itm: any) => itm.value == edit_data?.reason)
        if (tempRes) {
          setReasonAnamoly({ 'value': tempRes[0]?.value, 'label': tempRes[0]?.label })
        }
      } else {
        var tempRes: any = resonAnamolyNoOptions?.filter((itm: any) => itm.value == edit_data?.reason)
        if (tempRes) {
          setReasonAnamoly({ 'value': tempRes[0]?.value, 'label': tempRes[0]?.label })
        }
      }
    } else {
      verificationFormik.setFieldValue('ai_correct', true);
    }
  }, [])


  const verificationFormik: any = useFormik({
    initialValues: {
      operator_name: '',
      well_name: '',
      asset_name: '',
      ai_correct: '',
      correct_level: '',
      reason_anamoly: '',
      survey_start_date: '',
      survey_end_date: '',
      survey_start_time: "",
      survey_end_time: "",
      description: "",
      extra_attachment: ""
    },
    validationSchema: Yup.object().shape({
      operator_name: Yup.string().test('operator_name', "This field is required", function (value) {
        if (props?.title == "Edit") {
          return true
        } else {
          if (value) {
            return true
          } else {
            return false
          }
        }
      }),
      well_name: Yup.string().test('well_name', "This field is required", function (value) {
        if (props?.title == "Edit") {
          return true
        } else {
          if (value) {
            return true
          } else {
            return false
          }
        }
      }),
      asset_name: Yup.string().test('asset_name', "This field is required", function (value) {
        if (props?.title == "Add") {
          return true
        } else {
          if (value) {
            return true
          } else {
            return false
          }
        }
      }),
      ai_correct: Yup.string().required('This field is required'),
      correct_level: Yup.string().test('correct_level', 'This field is required', function (value) {
        if (value == undefined) {
          return false;
        } else {
          return true;
        }
      }),
      survey_start_date: Yup.string().required('This field is required'),
      survey_start_time: Yup.string().required('This field is required'),
      survey_end_date: Yup.string().required('This field is required'),
      survey_end_time: Yup.string().required('This field is required'),
      // survey_start_time: Yup.string().test('survey_start_time', "Please select start date", function (value) {
      //   if (surveyStartDate == null) {
      //     if (value == undefined) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else {
      //     return true;
      //   }

      // }),
      // survey_end_time: Yup.string().test('survey_end_time', "Please select end date", function (value) {
      //   if (surveyEndDate == null) {
      //     if (value == undefined) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   } else {
      //     return true;
      //   }
      // }),
    }),
    onSubmit: async (values: any) => {
      setVisibleForSubmit(true)
      values.wellId = wellId
      values.CustomerId = operatorId
      values.verifiedBy = curUserData?.userID
      setformValues(values);
    },
  });

  const handleOperatorName = (e: any) => {
    // console.log(e);
    if (e?.customerID) {
      setWellLoading(true)
      verificationFormik.setFieldValue('operator_name', e?.name);
      setOperatorName({ 'value': e?.name, 'name': e?.name })
      setOperatorId(e?.customerID);
      AnomalyDetectionService.getWellDetailsById(e?.customerID).then((res: any) => {
        if (res.length > 0) {
          setWellLoading(false)
          setAssetNameOptions(res)
        } else {
          setAssetNameOptions([])
          setWellLoading(false)
        }
      });
    }
  }

  const handleWellName = (e: any) => {
    if (e?.assetId) {
      verificationFormik.setFieldValue('well_name', e?.assetId);
      setWellName({ 'value': e?.assetName, 'assetName': e?.assetName })
      setWellId(e?.assetId)
      
    }
  }

  const handleCorrectLevel = (e: any) => {

    if (e?.value) {
      verificationFormik.setFieldValue('correct_level', e?.value);
      setCorrectLevel({ 'value': e?.value, 'label': e?.label })
      setEditCorrectLevel({ 'value': e?.value, 'label': e?.label })
    }
  }

  const handleAICorrectYesNo = (e: any) => {
    if (e?.target?.value == "true") {
      verificationFormik.setFieldValue('ai_correct', true);
      setCorrectLevel(EditCorrectLevel)
      verificationFormik.setFieldValue('correct_level', EditCorrectLevel.value);
      setAiYesNo(true)
    } else if (e?.target?.value == "false") {
      verificationFormik.setFieldValue('correct_level', "");

      verificationFormik.setFieldValue('ai_correct', false);
      setCorrectLevel([])
      setAiYesNo(false)
    }
    setReasonAnamoly([])
    verificationFormik.setFieldValue('reason_anamoly', "");
  }


  const handleReasonAnamoly = (e: any) => {
    if (e?.value) {
      if (props?.title != "Edit") {
        verificationFormik.setFieldValue('ai_correct', true);

      }
      verificationFormik.setFieldValue('reason_anamoly', e?.value);
      setReasonAnamoly({ 'value': e?.value, 'label': e?.label })
    }
  }
  const handleSurveyStartDate = (e: any) => {
    if (e?.value) {
      verificationFormik.setFieldValue('survey_start_date', e?.value);

      setSurveyStartDate(e?.value)
      setSurveyMinDate(e?.value)
    }
  }
  const handleSurveyStartTime = (e: any) => {
    if (e?.value) {
      verificationFormik.setFieldValue('survey_start_time', e?.value);
      setSurveyStartTime(e?.value)
    }
  }
  const handleSurveyEndDate = (e: any) => {
    if (e?.value) {
      verificationFormik.setFieldValue('survey_end_date', e?.value);
      setSurveyEndDate(e?.value)
    }

  }
  const handleSurveyEndTime = (e: any) => {
    if (e?.value) {
      verificationFormik.setFieldValue('survey_end_time', e?.value);
      setSurveyEndTime(e?.value)
    }
  }
  const handleFileChange = (e: any) => {
    let fildata = e.target.files[0];
    setFilPreview(fildata?.name)
    verificationFormik.setFieldValue('extra_attachment', fildata);
    setattachmentName("")
  }

  const getLatestAiSeverityLevel = (OperatorID: any, WellId: any) => {
    return new Promise((resolve, reject) => {
      let AISeverity: any = '';
      AnamolyDetectionData_Redux.forEach((val: any) => {
        if (val.assetId == WellId && val.customerID == OperatorID) {
          AISeverity = val.severity;
          resolve([AISeverity, val.anomalyType]);
        }
      })
    })
  }
  const handleFormSubmit = async (e: any) => {

    // let getLatestAiSeverityLevelVal: any = await getLatestAiSeverityLevel(operatorId, wellId);
    // let AiSeverity = getLatestAiSeverityLevelVal[0];
    // let AiAnomaly = getLatestAiSeverityLevelVal[1];
    // if (AiSeverity == 1 || AiSeverity == 'low') {
    //   AiSeverity = 'low';
    // } else if (AiSeverity == 2 || AiSeverity == 'medium') {
    //   AiSeverity = 'medium';
    // } else if (AiSeverity == 3 || AiSeverity == 'high') {
    //   AiSeverity = 'high';
    // } else if (AiSeverity == null || AiSeverity == -1) {
    //   AiSeverity = null;
    // } else if (AiSeverity == 0) {
    //   AiSeverity = 0;
    // }
    let formData: any = new FormData();
    // formData.append("AiAnomaly", AiAnomaly);
    // formData.append("AiSeverity", AiSeverity);
    formData.append("ThingNo", wellId);
    formData.append("CustomerID", operatorId);
    formData.append("VerifiedBy", curUserData?.userID);
    formData.append("State", "close");

    if (formValues?.description == null || formValues?.description == "null") {

    } else {

      formData.append("Comments", formValues?.description);

    }
    formData.append("status", Number(1));
    formData.append("AssetType", "Esp");
    formData.append("ModelType", "Esp");

    // must pass name
    if (wellName?.assetName == undefined) {
      formData.append("AssetName", formValues.asset_name);

    } else {
      formData.append("AssetName", wellName.assetName);

    }

    let sending_start_datetime: any = "";
    let sending_end_datetime: any = "";

    if (formValues?.survey_start_time.length == undefined) {
      sending_start_datetime = moment(formValues?.survey_start_date).format('MM/DD/YYYY') + " " + moment(formValues?.survey_start_time).format('h:mm A');

    } else {
      sending_start_datetime = moment(formValues?.survey_start_date).format('MM/DD/YYYY') + " " + formValues?.survey_start_time;
      // sending_start_datetime = formValues?.survey_start_date + " " + formValues?.survey_start_time;

    }



    if (formValues?.survey_end_date.length == undefined) {
      sending_end_datetime = moment(formValues?.survey_end_date).format('MM/DD/YYYY') + " " + moment(formValues?.survey_end_time).format('h:mm A');
    } else {
      sending_end_datetime = formValues?.survey_end_date + " " + formValues?.survey_end_time;
    }


    formData.append("FirstOccurance", moment.utc(moment(sending_start_datetime)).format('DD-MMM-YYYY') + " " + moment.utc(moment(sending_start_datetime)).format('h:mm A'));

    if (formValues?.survey_end_date == "") {
      formData.append("LastOccurance", "");
    } else {
      formData.append("LastOccurance", moment.utc(moment(sending_end_datetime)).format('DD-MMM-YYYY') + " " + moment.utc(moment(sending_end_datetime)).format('h:mm A'));
    }
    formData.append("Leak", formValues?.ai_correct);
    
    if (formValues?.ai_correct == true) {
      let severity_level = formValues?.correct_level;
      if(severity_level == 3){
        severity_level = "high";
      }else if(severity_level == 2){
        severity_level = "medium";
      }else if(severity_level == 1){
        severity_level = "low";
      }else if(severity_level == 0){
        severity_level = "normal";
      }
      formData.append("Severity", severity_level);

    }
    formData.append("Reason", formValues?.reason_anamoly);
    formData.append("Active", Boolean(true));
    formData.append("NewCreateIssue", props?.title == "Edit" ? Boolean(false) : Boolean(true));
    formData.append("UserChange", props?.title == "Edit" ? Boolean(true) : Boolean(false));
    formData.append("StateChange", "");
    formData.append("AIstatus", "");
    formData.append("QueryStartTime", undefined);
    formData.append("QueryEndTime", undefined);
    formData.append("Attachments", filPreview);

    if (props?.title == "Edit") {
      formData.append("tempID", edit_data?.tempID);
      formData.append("_id", edit_data?.tempID);

    } else {
      formData.append("tempID", "");

    }

    if (formValues?.extra_attachment != undefined) {
      formData.append("files", formValues?.extra_attachment);
    }
    AnomalyDetectionService.AddAnomalyDetectionGetWellSummaryService(AnamolyDetectionData_Redux, formData, formValues).then((res) => {
      if (props?.title == "Edit") {
        dispatch(addAnamolyDetectionStoreData([] as any));
      } else {
        dispatch(addAnamolyDetectionStoreData([] as any));
      }

      props?.setVisible(false)
      props?.title == "Edit" ? props?.UpdateCustomToast("update") : props?.UpdateCustomToast("add")
      setVisibleForSubmit(false);
      verificationFormik.resetForm();
      props?.title != "Edit" && props?.setResetData(Math.random())
    });
  }
  return (
    <>
      <div className="slimmScrolBar">
        <div className="">
          <div className="">
            <form className="p-fluid" onSubmit={verificationFormik.handleSubmit} encType="multipart/form-data">
              {
                props?.title == "Edit" ?
                  <>
                    <div className="field">
                      <label htmlFor="assetname" className={"custom_common_label "}>{t('AssetName')}<span className="p-error">&nbsp;*</span></label>
                      <span className="p-float-label">
                        <input disabled={true} type="text" name='asset_name' id="asset_name" className='form-control asset_name_prefilled_validate_popup' onChange={verificationFormik.handleChange} value={verificationFormik.values.asset_name} onBlur={verificationFormik.handleBlur} />
                        {verificationFormik.touched.asset_name && verificationFormik.errors.asset_name ? <div className="text-red">{verificationFormik.errors.asset_name}</div> : null}
                      </span>
                    </div>
                  </> :
                  <>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <label className='custom_common_label'>{t('PopupCall.OperatorName')}<span className="p-error">&nbsp;*</span></label>
                        <Select
                          className={'basic-single'}
                          classNamePrefix="select"
                          placeholder={t('AnamolyDetectionAnalyze.SerachForOpName')}
                          id="operator_name"
                          name="operator_name"
                          options={props.operatorList}
                          value={operatorName}
                          getOptionLabel={(option: any) => option.name}
                          onChange={(e) => handleOperatorName(e)}
                          onBlur={verificationFormik.handleBlur}
                        />
                        {verificationFormik.touched.operator_name && verificationFormik.errors.operator_name ? <div className="text-red">{verificationFormik.errors.operator_name}</div> : null}
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-6'>
                        <label className='custom_common_label'>{t('AssetName')}<span className="p-error">&nbsp;*</span></label>
                        <Select
                          className={'basic-single'}
                          classNamePrefix="select"
                          placeholder={t('SearchForAssetName')}
                          id="well_name"
                          name="well_name"
                          options={assetNameOptions}
                          value={wellName}
                          getOptionLabel={(option: any) => option.assetName}
                          onChange={(e) => handleWellName(e)}
                          onBlur={verificationFormik.handleBlur}
                          isLoading={wellLoading}
                        />
                        {verificationFormik.touched.well_name && verificationFormik.errors.well_name ? <div className="text-red">{verificationFormik.errors.well_name}</div> : null}
                      </div>
                    </div>
                  </>
              }

              {/* yes no and attachment section start */}
              {props?.title == "Edit" &&
                <div className="row mt-2" style={{ marginTop: "10px" }}>
                  <div className='col-sm-6 col-md-6 col-lg-6'>
                    <div className="field">
                      <label htmlFor="ai_correct" className={"custom_common_label"}>{t('PopupCall.AIAnamolyCallCorrect')}<span className="p-error">&nbsp;*</span></label><br />
                      <input type="radio" id="ai_correct_yes" name="ai_correct" value={"true"} onChange={(e) => handleAICorrectYesNo(e)} onBlur={verificationFormik.handleBlur} checked={aiYesNo == true ? true : false} />&nbsp;&nbsp;
                      <label htmlFor="ai_correct_yes">{t('PopupCall.AIAnamolyCallCorrectYes')}</label><br />
                      <input type="radio" id="ai_correct_no" name="ai_correct" value={"false"} onChange={(e) => handleAICorrectYesNo(e)} onBlur={verificationFormik.handleBlur} checked={aiYesNo == false ? true : false} />&nbsp;&nbsp;
                      <label htmlFor="ai_correct_no">{t('PopupCall.AIAnamolyCallCorrectNo')}</label>
                      {verificationFormik.touched.ai_correct && verificationFormik.errors.ai_correct ? <div className="text-red">{verificationFormik.errors.ai_correct}</div> : null}
                    </div>
                  </div>
                  <div className='col-sm-6 col-md-6 col-lg-6' style={{ visibility: "hidden" }}>
                    <label className='custom_common_label'>{t("Attachment")}</label><br></br>
                    <label htmlFor="choose-file2" className="border-0">
                      <i className="form-control border-0 pi pi-paperclip" aria-hidden="true" style={{ fontSize: '20px' }} aria-label="upload2">

                      </i>

                    </label>
                    <input style={{ display: "none" }} id="choose-file2" type="file" name={'extra_attachment'} onChange={(e) => handleFileChange(e)} /*accept="video/mp4,video/x-m4v,video/*"*/ />
                    {filPreview ? filPreview : ""}
                    <span>
                      {(attachmentName != "")
                        ?
                        <i className="pi pi-download" style={{ marginLeft: "2px", cursor: "pointer" }} onClick={(e) => {
                          AnomalyDetectionService.DownloadService(attachmentName).then((res) => {
                            fileDownload(res, attachmentName)

                          })
                        }}></i>
                        :
                        ""

                      }
                    </span>
                  </div>



                </div>
              }

              {/* yes no and attachment end */}
              <div className="row" style={{ marginTop: "10px" }}>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('PopupCall.CorrectSeverityLevel')}</label>
                  <span className="p-error">&nbsp;*</span>
                  <Select
                    className={'basic-single'}
                    classNamePrefix="select"
                    placeholder={t('AnamolyDetectionAnalyze.Select1Level')}
                    id="correct_level"
                    name="correct_level"
                    options={securityLevelOptions}
                    value={correctLevel}
                    onChange={(e) => handleCorrectLevel(e)}
                    onBlur={verificationFormik.handleBlur}
                  />
                  {verificationFormik.touched.correct_level && verificationFormik.errors.correct_level ? <div className="text-red">{verificationFormik.errors.correct_level}</div> : null}
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('PopupCall.ReasonForAnamoly')}</label>
                  <Tooltip target=".custom-target-icon" style={{ width: "200px", marginBottom: "5px" }} />
                  {
                    aiYesNo &&
                    <i className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"

                      data-pr-tooltip={"If the reason for the anomaly is not gas lock or gas slug,then select other."}
                      data-pr-position="top"
                      data-pr-at="right top-10"
                      // data-pr-my="left center-2"
                      style={{ fontSize: '12px', cursor: 'pointer', marginLeft: "5px", marginTop: "1px" }}>
                    </i>
                  }

                  <Select
                    className={'basic-single'}
                    classNamePrefix="select"
                    placeholder={t('SelectOne')}
                    id="reason_anamoly"
                    name="reason_anamoly"
                    options={props?.title != "Edit" ? resonAnamolyYesOptions : verificationFormik?.values?.ai_correct == true ? resonAnamolyYesOptions : verificationFormik?.values?.ai_correct == false ? resonAnamolyNoOptions : []}
                    value={reasonAnamoly}
                    onChange={(e) => handleReasonAnamoly(e)}
                    onBlur={verificationFormik.handleBlur}
                  />
                </div>
              </div>

              <div className="row mt-2" style={{ marginTop: "10px" }}>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('StartDate')}</label><span className="p-error">&nbsp;*</span>
                  <Calendar name="survey_start_date" onChange={(e: any) => handleSurveyStartDate(e)} id="survey_start_date" style={{ width: "100%" }} placeholder="Select date" showIcon value={surveyStartDate ? new Date(surveyStartDate) : null} maxDate={new Date()} />
                  {verificationFormik.touched.survey_start_date && verificationFormik.errors.survey_start_date ? <div className="text-red">{verificationFormik.errors.survey_start_date}</div> : null}
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('StartTime')}</label><span className="p-error">&nbsp;*</span>
                  <Calendar value={survey_start_time} onChange={(e: any) => handleSurveyStartTime(e)} id="survey_start_time" style={{ width: "100%" }} hourFormat="12" placeholder="Select time" showIcon timeOnly />
                  {verificationFormik.touched.survey_start_time && verificationFormik.errors.survey_start_time ? <div className="text-red">{verificationFormik.errors.survey_start_time}</div> : null}
                </div>
              </div>

              <div className="row mt-2" style={{ marginTop: "10px" }}>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('EndDate')}</label><span className="p-error">&nbsp;*</span>
                  <Calendar name="survey_end_date" onChange={(e: any) => handleSurveyEndDate(e)} id="survey_end_date" style={{ width: "100%" }} placeholder="Select date" showIcon minDate={surveyMinDate} value={surveyEndDate ? new Date(surveyEndDate) : null} />
                  {verificationFormik.touched.survey_end_date && verificationFormik.errors.survey_end_date ? <div className="text-red">{verificationFormik.errors.survey_end_date}</div> : null}
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <label className='custom_common_label'>{t('EndTime')}</label><span className="p-error">&nbsp;*</span>
                  <Calendar value={survey_end_time} onChange={(e: any) => handleSurveyEndTime(e)} id="survey_end_time" style={{ width: "100%" }} hourFormat="12" placeholder="Select time" showIcon timeOnly />
                  {verificationFormik.touched.survey_end_time && verificationFormik.errors.survey_end_time ? <div className="text-red">{verificationFormik.errors.survey_end_time}</div> : null}
                </div>
              </div>

              <div className="field mt-2" style={{ marginTop: "10px" }}>
                <label className='custom_common_label'>{t('Comments')}</label>
                <InputTextarea name='description' id='description' placeholder={`${t('PopupCall.CommentsPlaceholder')}`} rows={3} cols={20} onChange={verificationFormik.handleChange} value={verificationFormik.values.description} onBlur={verificationFormik.handleBlur} />
              </div>

              <div className="row mt-2">
                <div className='col-sm-6 col-md-6 col-lg-6'>
                  <span className="p-error">*</span>{t('IndicatesMandatoryFields')}
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6 float-right pull-right'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Button type="button" label="Cancel" className="btn btn-md p-button-text btn" onClick={(e) => props?.setVisible(false)} style={{ border: '1px solid' }} />
                    </div>
                    <div className='col-md-6'>
                      <Button type="submit" label="Submit" className='btn btn-md btn-primary' />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >

      <Dialog header={<Icon />} visible={visibleForSubmit} style={{ width: '20vw' }} onHide={() => setVisibleForSubmit(false)}>
        <div>
          {
            props.title == "Edit" ?
              <>
                {t('PopupCall.verificationSubmitted')}
                <p className='mt-2' style={{ fontSize: '12px', fontWeight: 400 }}>{t('PopupCall.verificationSubmittedCloseWorkOrder')}</p>
              </> :
              <>
                {t('PopupCall.CreateNewAnamolyCall')}
                <p className='mt-2' style={{ fontSize: '12px', fontWeight: 400 }}>{t('PopupCall.FillInfoAboutAnamoly')}</p>
              </>
          }

        </div>
        <div style={{ fontSize: '12px', fontWeight: 300, textAlign: "center" }}>
          <div className='row' >
            <div className='col-md-6' >
              <Button severity="secondary" type="button" style={{ width: '100%', border: '1px solid #ccc' }} label="Cancel" className="p-button-text" onClick={(e) => setVisibleForSubmit(false)} outlined />
            </div>
            <div className='col-md-6'>
              <Button type="button" style={{ width: '100%' }} label="Confirm" onClick={(e) => handleFormSubmit(e)} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default AnamolyVerificationAddEditPopUp
import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { OverlayPanel } from "primereact/overlaypanel";
import { useTranslation } from "react-i18next";
import DraggableList from "react-draggable-list";
const SelectNormalFilterRawTrendHistory = (props: any) => {
    const { t } = useTranslation();

    const filterDpDown = useRef<any>(null);

    const [eyeStateCurrentAnamoly, seteyeStateCurrentAnamoly] = useState('pi-eye');
    const [eyeStatePastAnamoly, seteyeStatePastAnamoly] = useState('pi-eye');
    const [eyeStateFrequncyMotor, seteyeStateFrequncyMotor] = useState('pi-eye');
    const [eyeStateTempMotor, seteyeStateTempMotor] = useState('pi-eye');
    const [eyePressIntake, seteyePressIntake] = useState('pi-eye');
    const [tempIntake, settempIntake] = useState('pi-eye');
    const [currMotor, settempcurrMotor] = useState('pi-eye');
    const [currPressure, setcurrPressure] = useState('pi-eye');
    const [TubingPressure, setTubingPressure] = useState('pi-eye');

    useEffect(() => {
        if (props.viewCurrentAnamolyState) {
            seteyeStateCurrentAnamoly("pi-eye");
        } else {
            seteyeStateCurrentAnamoly("pi-eye-slash");

        }

        if (props.viewPastAnamolyState) {
            seteyeStatePastAnamoly("pi-eye");
        } else {
            seteyeStatePastAnamoly("pi-eye-slash");

        }

    }, [props])
    const raw_list = [
        { id: 1, name: "freq_motor" },
        { id: 2, name: "temp_motor" },
        { id: 3, name: "press_intake" },
        { id: 4, name: "temp_intake" },
        { id: 5, name: "current_motor" },
        { id: 6, name: "current_pressure" },
        { id: 7, name: "tubing_pressure" },
    ];
    const [list, setList] = useState(raw_list);
    const containerRef = useRef();
    const _onListChange = (newList: any) => {
        setList(newList);
        props.setSequence(newList);
    };
    const Item = ({ item, itemSelected, dragHandleProps }: any) => {
        const { onMouseDown, onTouchStart } = dragHandleProps;

        return (
            <>
                {item.name == "freq_motor" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (eyeStateFrequncyMotor == "pi-eye") {
                            seteyeStateFrequncyMotor('pi-eye-slash');
                            props.setcheckFilterFreqMotor(false);
                        } else {
                            seteyeStateFrequncyMotor('pi-eye');
                            props.setcheckFilterFreqMotor(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{t("AnamolyDetectionAnalyze.FrequencyMotorHz")}</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${eyeStateFrequncyMotor}`}></i>
                        </label>
                    </div>
                }

                {item.name == "temp_motor" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (eyeStateTempMotor == "pi-eye") {
                            seteyeStateTempMotor('pi-eye-slash');
                            props.setcheckFilterTempMotor(false);

                        } else {
                            seteyeStateTempMotor('pi-eye');
                            props.setcheckFilterTempMotor(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{t("AnamolyDetectionAnalyze.TemperatureMotorF")}(&#x2109;)</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${eyeStateTempMotor}`} ></i>
                        </label>
                    </div>
                }

                {item.name == "press_intake" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (eyePressIntake == "pi-eye") {
                            seteyePressIntake('pi-eye-slash');
                            props.setcheckFilterPressureINtake(false);

                        } else {
                            seteyePressIntake('pi-eye');
                            props.setcheckFilterPressureINtake(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{t("AnamolyDetectionAnalyze.PressureIntakePSI")}</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${eyePressIntake}`} ></i>
                        </label>
                    </div>
                }

                {item.name == "temp_intake" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (tempIntake == "pi-eye") {
                            settempIntake('pi-eye-slash');
                            props.setcheckFilterTempIntake(false);

                        } else {
                            settempIntake('pi-eye');
                            props.setcheckFilterTempIntake(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{ t("AnamolyDetectionAnalyze.TemperatureIntakeF")} (&#x2109;)</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${tempIntake}`} ></i>
                        </label>
                    </div>
                }

                {item.name == "current_motor" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (currMotor == "pi-eye") {
                            settempcurrMotor('pi-eye-slash');
                            props.setcheckFilterCurrMotor(false);

                        } else {
                            settempcurrMotor('pi-eye');
                            props.setcheckFilterCurrMotor(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{t("AnamolyDetectionAnalyze.CurrentMotorAMP")}</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${currMotor}`} ></i>
                        </label>
                    </div>

                }
                {item.name == "current_pressure" &&
                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (currPressure == "pi-eye") {
                            setcurrPressure('pi-eye-slash');
                            props.setcheckFilterCurrPress(false);

                        } else {
                            setcurrPressure('pi-eye');
                            props.setcheckFilterCurrPress(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{'Casing - pressure'} (psi)</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${currPressure}`} ></i>
                        </label>
                    </div>

                }

                {item.name == "tubing_pressure" &&

                    <div className="flex align-items-center mt-2" onClick={(e) => {
                        if (TubingPressure == "pi-eye") {
                            setTubingPressure('pi-eye-slash');
                            props.setcheckFilterTubingPress(false);

                        } else {
                            setTubingPressure('pi-eye');
                            props.setcheckFilterTubingPress(true);

                        }
                    }} style={{ cursor: "pointer" }}>
                        <i className='pi pi-ellipsis-v' style={{ marginRight: "-10px" }}></i><i className='pi pi-ellipsis-v' onTouchStart={(e: any) => {
                            e.preventDefault();
                            console.log("touchStart");
                            // e.target.style.backgroundColor = "blue";
                            // document.body.style.overflow = "hidden";
                            onTouchStart(e);
                        }}
                            onMouseDown={(e) => {
                                console.log("mouseDown");
                                // document.body.style.overflow = "hidden";
                                onMouseDown(e);
                            }}
                            onTouchEnd={(e: any) => {
                                // e.target.style.backgroundColor = "black";
                                // document.body.style.overflow = "visible";
                            }}
                            onMouseUp={() => {
                                // document.body.style.overflow = "visible";
                            }}></i>
                        <span className='p-2'>{t("AnamolyDetectionAnalyze.TubingPressurePSI")}</span>
                        <label htmlFor={"rsp"} className="ml-2 float_eyes_toRight_raw">
                            <i className={`pi ${TubingPressure}`} ></i>
                        </label>
                    </div>

                }

            </>
        );
    };
    return (
        <>
            <Button severity="secondary" text raised style={{ fontWeight:"500",height: "30px", fontSize: "12px", float: "right",marginLeft:"12px" }} onClick={(e) => filterDpDown.current.toggle(e)}>
                <i className='pi pi-filter' />&nbsp;{t('AnamolyDetectionAnalyze.filters')}
            </Button>
            <OverlayPanel ref={filterDpDown}>
                <div className="flex align-items-center mt-2" style={{ fontWeight: 500 }}>
                    {t("AnamolyDetectionAnalyze.ViewOptions")}
                </div>
                <div className="flex align-items-center mt-4" style={{ cursor: "pointer" }} onClick={() => {
                    if (eyeStateCurrentAnamoly == "pi-eye") {
                        seteyeStateCurrentAnamoly('pi-eye-slash');
                        props.setviewCurrentAnamolyState(false);

                    } else {
                        seteyeStateCurrentAnamoly('pi-eye');
                        props.setviewCurrentAnamolyState(true);


                    }
                }}>
                    {t('AnamolyDetectionAnalyze.ViewCurrentAnamoly')}
                    <label htmlFor={"rsp"} className="ml-2 float_eyes_toRightviewOP">
                        <i className={`pi ${eyeStateCurrentAnamoly}`}></i>
                    </label>
                </div>


                <div className="flex align-items-center mt-4" style={{ cursor: "pointer" }} onClick={() => {
                    if (eyeStatePastAnamoly == "pi-eye") {
                        seteyeStatePastAnamoly('pi-eye-slash');
                        props.setviewPastAnamolyState(false);

                    } else {
                        seteyeStatePastAnamoly('pi-eye');
                        props.setviewPastAnamolyState(true);


                    }
                }}>
                    {t('AnamolyDetectionAnalyze.ViewHistoryAnamolies')}
                    <label htmlFor={"rsp"} className="ml-2 float_eyes_toRightviewOP">
                        <i className={`pi ${eyeStatePastAnamoly}`}></i>
                    </label>
                </div>
                <hr style={{ border: "1px solid" }} />
                <div className="flex align-items-center mt-2" style={{ fontWeight: 500 }}>
                    {'Trend lines'}
                </div>


                <div
                    ref={containerRef as any}
                style={{ touchAction: "pan-y",width:"210px" }}
                >
                    <DraggableList
                        itemKey="id"
                        template={Item as any}
                        list={list}
                        onMoveEnd={(newList: any) => _onListChange(newList)}
                        container={() => containerRef.current}
                    />
                    {/* {list.map((item) => (
          <Item item={item} />
        ))} */}
                </div>
            </OverlayPanel>
        </>
    )
}

export default SelectNormalFilterRawTrendHistory;
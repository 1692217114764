
const CommonApiConstant: any = {
    getToken: "/api/token",
    getAllCustomerList: "/api/customer/getallcustomers",
    getUser: "/api/auth/getuser",
    dowloadFiles: "/api/assets/DownloadBlobByID",
    getPermissionsOfCurrentUser: "api/auth/getUserPermissions",
    GetESPSummary: "api/ESP/GetESPSummary",
    GetEspPastVerifications: "api/ESP/GetEspPastVerifications",
    GetAICallVHealthIndex:"api/ESP/GetAICallVHealthIndex",
    GetRawFeatures:"api/ESP/GetRawFeatures",
    AddUpdateESPVerification: "api/ESP/ESPVerification",
    GetEspVerificationlog: "api/ESP/GetEspVerificationlog",
    DownloadFile: "api/ESP/DownloadFile",
    GetCustomersByProductType: "api/ESP/GetCustomersByProductType",
};

export default CommonApiConstant;

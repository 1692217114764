// Prime React
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
// react router
import { useNavigate } from 'react-router-dom';

// React
import { useEffect, useState, useContext, useRef } from 'react';

// Redux Store
import { useSelector, useDispatch } from 'react-redux';
import { addAnamolyDetectionStoreData, addOperatorStoreData } from '../../store/esp-ai/esp-anomaly-detection.store';
import { AppDispatch, AppState } from '../../store/esp-ai/store';

// Services
import CustomerService from '../../../common/services/customer/customer.service';
import AnomalyDetectionService from '../../services/anamolt-detection/anomaly-detection.services';


// components
import Aitrend from './aitrend';

// Components
import AnamolyVerificationAddEditPopUp from './anamoly-verification-add-edit-popup';
import LoadingScreen from "../common/loading";
import HistoryAnalyst from './History-analyst';

// i18n
import { useTranslation } from "react-i18next";

// React Router
import { useParams } from 'react-router-dom';

// Layout
import { LayoutContext } from '../../../common/components/layout/context/layout-context';
import moment from 'moment';

const Analyze = () => {
  const { t } = useTranslation();
  const { onMenuToggle } = useContext(LayoutContext);
  const navigate = useNavigate();

  const toast = useRef<any>(null);

  const dispatch: AppDispatch = useDispatch();
  const OperatorData_Redux: any = useSelector((state: AppState) => state.EspAnomalyDetectionStore.OperatorStoreData);
  const AnamolyDetectionData_Redux: any = useSelector((state: AppState) => state.EspAnomalyDetectionStore.AnamolyDetectionStoreData);

  const [CurDataESPCallHistory, setCurDataESPCallHistory] = useState([]);
  const [CurPageAssetName, setCurPageAssetName] = useState("");
  const [CurPageAIvsHeathIndex, setCurPageAIvsHeathIndex] = useState([]);
  const [CurPageRawTrend, setCurPageRawTrend] = useState([]);
  const [CurPageRawTrendAnamolyType, setCurPageRawTrendAnamolyType] = useState([]);
  const [CurPageAnalystHistory, setCurPageAnalystHistory] = useState([]);
  const [chosenDay, setChosenDay] = useState<any>("");
  const [chosenDayDateRange, setchosenDayDateRange] = useState<any>("");
  const [zoomDateRange, setzoomDateRange] = useState<any>([]);
  const [tempEditData, setTempEditData] = useState<any>("");
  const [customToast, UpdateCustomToast] = useState<any>("");
  const [isLoading, isLoadingUpdate] = useState(false);

  const [StartDateCurrentAnomaly, setStartDateCurrentAnomaly] = useState("");
  const [EndDateCurrentAnomaly, setEndDateCurrentAnomaly] = useState("");

  const { assetID } = useParams();
  const { assetNAME } = useParams();
  const { customerID } = useParams();
  const { custName } = useParams();
  const { startDate }: any = useParams();
  const { endDate }: any = useParams();


  let currentAnamolyStartDate: any = startDate;
  let currentAnamolyEndDate: any = endDate;


  useEffect(() => {

    isLoadingUpdate(true);
    setchosenDayDateRange("");
    var d = new Date();
    let hours = 0;
    if (chosenDay == "A") {
      hours = 6;
    } else if (chosenDay == "M") {
      hours = 12;

    } else if (chosenDay == "P") {
      hours = 24;

    } else if (chosenDay == "R") {
      hours = 168;

    }
    if (hours != 0) {

      let EndD = new Date();
      let StartD = new Date();
      StartD.setHours(StartD.getHours() - hours);

      AnomalyDetectionService.AnomalyDetectionGetWellSubSummaryService(customerID, assetID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
        if (res?.length > 0) {
          res.map((val: any) => {

            // severity prediction
            if (val?.anomaly == 0) {
              val.search_anomly = "Normal";
            } else if (val?.anomaly == -4 || val?.severity == -4) {
              val.search_anomly = "ShutDown";
  
            } else if (val.severity == "1" || val?.severity == "low") {
              val.search_anomly = "Level 1";
  
            } else if (val.severity == "2" || val?.severity == "medium") {
              val.search_anomly = "Level 2";
  
            } else if (val.severity == "3" || val?.severity == "high") {
              val.search_anomly = "Level 3";
  
            } else if (val.severity == "0") {
              val.search_anomly = "Normal";
  
            } else if (val.severity == null || val.severity == "-1" || val.severity == "-2" || val.severity == "-3") {
              val.search_anomly = "Unknown";
  
            } else {
              val.search_anomly = "";
            }
  
            // verified severity start
            let check_temp_length1 = val?.histories?.length;
  
            if (check_temp_length1 > 0) {
              let severity = val?.histories[check_temp_length1 - 1]?.severity;
              if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                val.search_verified_severity = "Normal";
              } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
                val.search_verified_severity = "Shutdown";
              } else if (severity == "1" || severity == "low") {
                val.search_verified_severity = "Level 1";
              } else if (val.severity == "2" || val?.severity == "medium") {
                val.search_verified_severity = "Level 2";
              } else if (severity == "3" || severity == "high") {
                val.search_verified_severity = "Level 3";
              } else if (severity == null) {
                val.search_verified_severity = "Unknown";
              }
  
  
  
  
            } else {
              val.search_verified_severity = "";
            }
  
            // verified predition 
            let check_temp_length = val?.histories?.length;
  
            if (check_temp_length > 0) {
                let verifiedOn = val?.histories[check_temp_length - 1]?.verifiedOn;
  
                if (verifiedOn != null) {
                    val.search_verified = "Verified";
                    val.search_verified = "Needs verification";
                } else {
                    val.search_verified = "Needs verification";
                }
  
            } else {
                val.search_verified = "Needs verification";
            }
  
            // check comment
            let check_temp_length_comments = val?.histories?.length;
  
            if (check_temp_length_comments > 0) {
                let comments = val?.histories[check_temp_length - 1]?.comments;
  
                val.search_comments = comments;
  
            } else {
                val.search_comments = "";
  
            }
  
            // verified type
            let check_temp_length2 = val?.histories?.length;
  
            if (check_temp_length2 > 0) {
                let reason = val?.histories[check_temp_length2 - 1]?.reason;
  
                if (reason == "Gasslug") {
                    val.search_verified_type = "Gas slug"
                } else if (reason == "Gaslock") {
                    val.search_verified_type = "Gas lock"
                }
  
            } else {
                return <>N/A</>
            }
  
  
  
          })

          setCurDataESPCallHistory(res);
          let temp_data: any = res[0];
          temp_data = temp_data.assetName;
          setCurPageAssetName(temp_data);
        } else {
          setCurDataESPCallHistory([]);
        }

      });
      AnomalyDetectionService.GetAICallVHealthIndex(assetID, customerID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
        setCurPageAIvsHeathIndex(res);
      });
      AnomalyDetectionService.GetRawFeatures(assetID, customerID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
        setCurPageRawTrend(res?.chartData);

        if (res?.verificationReason == '') {
          setCurPageRawTrendAnamolyType('N-A' as any);
        } else {
          let vaerificationReason: any = "";
          if (res?.verificationReason == "Gaslock") {
            vaerificationReason = "Gas lock";
          } else if (res?.verificationReason == "Gasleak") {
            vaerificationReason = "Gas leak";
          } else if (res?.verificationReason == "Gasslug") {
            vaerificationReason = "Gas slug";
          } else {
            vaerificationReason = res?.verificationReason;
          }
          setCurPageRawTrendAnamolyType(vaerificationReason);

        }

        // isLoadingUpdate(false);

      });
      AnomalyDetectionService.GetEspVerificationlog(customerID, StartD.toISOString(), EndD.toISOString(), assetID).then((res: any) => {
        setCurPageAnalystHistory(res)
        // setCurPageRawTrend(res?.chartData);
        isLoadingUpdate(false);
      });
    }

    setTimeout(() => {
      isLoadingUpdate(false);

    }, 7000)
  }, [chosenDay]);


  useEffect(() => {
    var container: any = document.getElementById('menu_div_root');
    container.classList.add("mn--min");
    container.classList.remove("mn--max");
    var container: any = document.getElementById('popupmenu');
    container.classList.remove("show")
  })

  useEffect(() => {
    setChosenDay("");
    isLoadingUpdate(true);
    let RangeDate = chosenDayDateRange;
    if (RangeDate[0] != null && RangeDate[1] != null) {
      let startD: any = new Date(chosenDayDateRange[0]);
      let EndD: any = new Date(chosenDayDateRange[1]);

      const now = new Date();

      if ((now.getMonth() == EndD.getMonth()) && (now.getDate() == EndD.getDate())) {
        EndD.setHours(now.getHours());
        EndD.setMinutes(now.getMinutes());
      }



      AnomalyDetectionService.AnomalyDetectionGetWellSubSummaryService(customerID, assetID, startD.toISOString(), EndD.toISOString()).then((res: any) => {
        if (res?.length > 0) {
          res.map((val: any) => {

            // severity prediction
            if (val?.anomaly == 0) {
              val.search_anomly = "Normal";
            } else if (val?.anomaly == -4 || val?.severity == -4) {
              val.search_anomly = "ShutDown";
  
            } else if (val.severity == "1" || val?.severity == "low") {
              val.search_anomly = "Level 1";
  
            } else if (val.severity == "2" || val?.severity == "medium") {
              val.search_anomly = "Level 2";
  
            } else if (val.severity == "3" || val?.severity == "high") {
              val.search_anomly = "Level 3";
  
            } else if (val.severity == "0") {
              val.search_anomly = "Normal";
  
            } else if (val.severity == null || val.severity == "-1" || val.severity == "-2" || val.severity == "-3") {
              val.search_anomly = "Unknown";
  
            } else {
              val.search_anomly = "";
            }
  
            // verified severity start
            let check_temp_length1 = val?.histories?.length;
  
            if (check_temp_length1 > 0) {
              let severity = val?.histories[check_temp_length1 - 1]?.severity;
              if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
                val.search_verified_severity = "Normal";
              } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
                val.search_verified_severity = "Shutdown";
              } else if (severity == "1" || severity == "low") {
                val.search_verified_severity = "Level 1";
              } else if (val.severity == "2" || val?.severity == "medium") {
                val.search_verified_severity = "Level 2";
              } else if (severity == "3" || severity == "high") {
                val.search_verified_severity = "Level 3";
              } else if (severity == null) {
                val.search_verified_severity = "Unknown";
              }
  
  
  
  
            } else {
              val.search_verified_severity = "";
            }
  
            // verified predition 
            let check_temp_length = val?.histories?.length;
  
            if (check_temp_length > 0) {
                let verifiedOn = val?.histories[check_temp_length - 1]?.verifiedOn;
  
                if (verifiedOn != null) {
                    val.search_verified = "Verified";
                    val.search_verified = "Needs verification";
                } else {
                    val.search_verified = "Needs verification";
                }
  
            } else {
                val.search_verified = "Needs verification";
            }
  
            // check comment
            let check_temp_length_comments = val?.histories?.length;
  
            if (check_temp_length_comments > 0) {
                let comments = val?.histories[check_temp_length - 1]?.comments;
  
                val.search_comments = comments;
  
            } else {
                val.search_comments = "";
  
            }
  
            // verified type
            let check_temp_length2 = val?.histories?.length;
  
            if (check_temp_length2 > 0) {
                let reason = val?.histories[check_temp_length2 - 1]?.reason;
  
                if (reason == "Gasslug") {
                    val.search_verified_type = "Gas slug"
                } else if (reason == "Gaslock") {
                    val.search_verified_type = "Gas lock"
                }
  
            } else {
                return <>N/A</>
            }
  
  
  
          })
          setCurDataESPCallHistory(res);
          let temp_data: any = res[0];
          temp_data = temp_data.assetName;
          setCurPageAssetName(temp_data);
        } else {
          setCurDataESPCallHistory([]);
        }

      });
      AnomalyDetectionService.GetAICallVHealthIndex(assetID, customerID, startD.toISOString(), EndD.toISOString()).then((res: any) => {
        setCurPageAIvsHeathIndex(res);
      });
      AnomalyDetectionService.GetRawFeatures(assetID, customerID, startD.toISOString(), EndD.toISOString()).then((res: any) => {
        setCurPageRawTrend(res?.chartData);

        if (res?.verificationReason == '') {
          setCurPageRawTrendAnamolyType('N-A' as any);
        } else {
          let vaerificationReason: any = "";
          if (res?.verificationReason == "Gaslock") {
            vaerificationReason = "Gas lock";
          } else if (res?.verificationReason == "Gasleak") {
            vaerificationReason = "Gas leak";
          } else if (res?.verificationReason == "Gasslug") {
            vaerificationReason = "Gas slug";
          } else {
            vaerificationReason = res?.verificationReason;
          }
          setCurPageRawTrendAnamolyType(vaerificationReason);

        }
        // isLoadingUpdate(false);
      })
      AnomalyDetectionService.GetEspVerificationlog(customerID, startD.toISOString(), EndD.toISOString(), assetID).then((res: any) => {
        setCurPageAnalystHistory(res)
        // setCurPageRawTrend(res?.chartData);
        isLoadingUpdate(false);
      });


    }
    setTimeout(() => {
      isLoadingUpdate(false);

    }, 7000);
  }, [chosenDayDateRange]);

  useEffect(() => {
    if (customToast == "add") {
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Added Successfully', life: 10000 });
      UpdateCustomToast("");
    } else if (customToast == "update") {
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Updated Successfully', life: 10000 });
      UpdateCustomToast("");
    }

  }, [customToast])

  useEffect(() => {
    isLoadingUpdate(true);
    if (OperatorData_Redux.length == 0) {

      CustomerService.getCustomerList().then((res: any) => {
        setshowOperatorList(res)
        dispatch(addOperatorStoreData(res as any));
      });
    } else {
      setshowOperatorList(OperatorData_Redux)
    }


    let EndD = new Date();
    let StartD = new Date();
    if (endDate == 'null') {

      StartD.setHours(StartD.getHours() - 24);

    } else {
      EndD = new Date(endDate);
      StartD = new Date(startDate);
      let __duration = moment.duration(moment(moment(endDate).format()).diff(moment(startDate).format()));
      let __hours = __duration.asHours();
      if (__hours < 25) {
        let makeHalfHour = (24 - __hours) / 2;
        StartD.setHours(StartD.getHours() - makeHalfHour);
        EndD.setHours(EndD.getHours() + makeHalfHour);
        setStartDateCurrentAnomaly(StartD as any);
        setEndDateCurrentAnomaly(EndD as any);
      } else {
        StartD.setHours(StartD.getHours() - 48);
        EndD.setHours(EndD.getHours() + 48);
        setStartDateCurrentAnomaly(StartD as any);
        setEndDateCurrentAnomaly(EndD as any);
      }

    }

    AnomalyDetectionService.AnomalyDetectionGetWellSubSummaryService(customerID, assetID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
      if (res?.length > 0) {
        res.map((val: any) => {

          // severity prediction
          if (val?.anomaly == 0) {
            val.search_anomly = "Normal";
          } else if (val?.anomaly == -4 || val?.severity == -4) {
            val.search_anomly = "ShutDown";

          } else if (val.severity == "1" || val?.severity == "low") {
            val.search_anomly = "Level 1";

          } else if (val.severity == "2" || val?.severity == "medium") {
            val.search_anomly = "Level 2";

          } else if (val.severity == "3" || val?.severity == "high") {
            val.search_anomly = "Level 3";

          } else if (val.severity == "0") {
            val.search_anomly = "Normal";

          } else if (val.severity == null || val.severity == "-1" || val.severity == "-2" || val.severity == "-3") {
            val.search_anomly = "Unknown";

          } else {
            val.search_anomly = "";
          }

          // verified severity start
          let check_temp_length1 = val?.histories?.length;

          if (check_temp_length1 > 0) {
            let severity = val?.histories[check_temp_length1 - 1]?.severity;
            if (severity == 0 || severity == 'normal' || severity == 'NORMAL') {
              val.search_verified_severity = "Normal";
            } else if (severity == -1 || severity == "shutdown" || severity == "SHUTDOWN") {
              val.search_verified_severity = "Shutdown";
            } else if (severity == "1" || severity == "low") {
              val.search_verified_severity = "Level 1";
            } else if (val.severity == "2" || val?.severity == "medium") {
              val.search_verified_severity = "Level 2";
            } else if (severity == "3" || severity == "high") {
              val.search_verified_severity = "Level 3";
            } else if (severity == null) {
              val.search_verified_severity = "Unknown";
            }




          } else {
            val.search_verified_severity = "";
          }

          // verified predition 
          let check_temp_length = val?.histories?.length;

          if (check_temp_length > 0) {
              let verifiedOn = val?.histories[check_temp_length - 1]?.verifiedOn;

              if (verifiedOn != null) {
                  val.search_verified = "Verified";
                  val.search_verified = "Needs verification";
              } else {
                  val.search_verified = "Needs verification";
              }

          } else {
              val.search_verified = "Needs verification";
          }

          // check comment
          let check_temp_length_comments = val?.histories?.length;

          if (check_temp_length_comments > 0) {
              let comments = val?.histories[check_temp_length - 1]?.comments;

              val.search_comments = comments;

          } else {
              val.search_comments = "";

          }

          // verified type
          let check_temp_length2 = val?.histories?.length;

          if (check_temp_length2 > 0) {
              let reason = val?.histories[check_temp_length2 - 1]?.reason;

              if (reason == "Gasslug") {
                  val.search_verified_type = "Gas slug"
              } else if (reason == "Gaslock") {
                  val.search_verified_type = "Gas lock"
              }

          } else {
              return <>N/A</>
          }



        })
        setCurDataESPCallHistory(res);
        let temp_data: any = res[0];
        temp_data = temp_data.assetName;
        setCurPageAssetName(temp_data);
      } else {
        setCurDataESPCallHistory([])
      }

    });
    AnomalyDetectionService.GetAICallVHealthIndex(assetID, customerID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
      setCurPageAIvsHeathIndex(res);
    });
    AnomalyDetectionService.GetRawFeatures(assetID, customerID, StartD.toISOString(), EndD.toISOString()).then((res: any) => {
      setCurPageRawTrend(res?.chartData);

      if (res?.verificationReason == '') {
        setCurPageRawTrendAnamolyType('N-A' as any);
      } else {
        let vaerificationReason: any = "";
        if (res?.verificationReason == "Gaslock") {
          vaerificationReason = "Gas lock";
        } else if (res?.verificationReason == "Gasleak") {
          vaerificationReason = "Gas leak";
        } else if (res?.verificationReason == "Gasslug") {
          vaerificationReason = "Gas slug";
        } else {
          vaerificationReason = res?.verificationReason;
        }
        setCurPageRawTrendAnamolyType(vaerificationReason);

      }

      // isLoadingUpdate(false);
    });
    AnomalyDetectionService.GetEspVerificationlog(customerID, StartD.toISOString(), EndD.toISOString(), assetID).then((res: any) => {
      setCurPageAnalystHistory(res)
      // setCurPageRawTrend(res?.chartData);
      isLoadingUpdate(false);
    });

    setTimeout(() => {
      isLoadingUpdate(false);

    }, 7000);
    onMenuToggle();
    // get session time range start
    let session = window.localStorage;
    let session_checkvalueISO: any = session.getItem('summary_filter_dates');
    if (session_checkvalueISO == "" || session_checkvalueISO == undefined || session_checkvalueISO == null) {
      navigate('/dashboard')
    }
    let session_checkvalueISO_array: any = session_checkvalueISO?.split(",");

    let anomalyDetectionFilterStartDate: any = new Date();
    let anomalyDetectionFilterEndDate: any = new Date();

    let startD1 = new Date(session_checkvalueISO_array[0]);
    let EndD1 = new Date(session_checkvalueISO_array[1]);

    anomalyDetectionFilterStartDate = startD1.toISOString();
    anomalyDetectionFilterEndDate = EndD1.toISOString();

    // get session time range end
    if (AnamolyDetectionData_Redux?.length == 0) {
      AnomalyDetectionService.AnomalyDetectionGetWellSummaryService(anomalyDetectionFilterStartDate, anomalyDetectionFilterEndDate).then((res: any) => {
        let temp_res = JSON.parse(JSON.stringify(res));
        let res_without_null_values = temp_res?.filter((val: any) => {
          if (val?.firstOccurance == null) {
            return false;
          } else {
            return true;
          }
        });

        let testId = 1;
        res_without_null_values?.map((val: any, id: any) => {
          val.id = testId++;

          if (val?.reason == "") {
            val.reason = null;
          }

          /*if (val?.anomaly == 'NORMAL') {
            val.order = 0;
          } else*/ if (val?.anomaly == 2 || val?.anomaly == -1) {
            val.order = -1;

          } else if (val?.severity == "1" || val?.severity == "low") {
            val.order = 1;

          } else if (val.severity == "2" || val?.severity == "medium") {
            val.order = 2;

          } else if (val?.severity == "3" || val?.severity == "high") {
            val.order = 3;

          } else if (val?.severity == null) {
            val.order = null;

          } else if (val?.severity == "0" || val?.severity == "normal") {
            val.order = 0;

          }

          if (val?.action == "NeedsVerification") {
            val.orderAction = val?.action
          } else if (val?.action == "" || val?.action == 'Normal' || val?.action == 'Anomaly Predicted') {
            val.orderAction = "";
          }

        });

        dispatch(addAnamolyDetectionStoreData(res_without_null_values));
      });
    }
  }, []);


  const [visible, setVisible] = useState(false);
  const [popUpTitle, setPopUpTitle] = useState<string>("");
  const [showOperatorList, setshowOperatorList] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const handlePopBtn = () => {
    setPopUpTitle("Add")
    setVisible(true)
  }

  const handlePopBtnEdit = () => {
    const tempEData = AnamolyDetectionData_Redux.filter((e: any) => {
      if (e?.assetId == assetID && e?.customerID == customerID && e?.firstOccurance == decodeURIComponent(startDate)) {
        setTempEditData(e)
      }
    })
    setPopUpTitle("Edit")
    setVisible(true)
  }

  const items = [
    { label: t('AnamolyDetectionAnalyze.AiTrends') },
    { label: t('AnamolyDetectionAnalyze.AnalystHistory') }
  ];
  const Icon = () => {
    return <>
      <div style={{ textAlign: "center", width: '30px', height: '30px', background: '#ddf2fc', margin: '0 auto', borderRadius: '25px'/*, padding: '0.7rem 0.7rem'*/ }}>
        <i className="pi pi-check-circle" style={{ color: '#1e97e9', fontSize: '20px' }}></i>
      </div>
      <div>
        {
          popUpTitle == "Edit" ?
            <>
              {t("PopupCall.verificationSubmitted")}
              <p style={{ fontSize: '12px', fontWeight: 400 }}>{t("PopupCall.FillInfoVerifyAnamolyDetected")}</p>
            </> :
            <>
              {t("PopupCall.CreateNewcall")}
              <p style={{ fontSize: '12px', fontWeight: 400 }}>{t("PopupCall.FillInfoAboutAnamoly")}</p>
            </>
        }
      </div>
    </>
  }
  const Header = () => {
    document.addEventListener("mousemove", () => {
      let test_dat: any = new Date();
      localStorage.setItem('lastActvity', test_dat)
    });
    document.addEventListener("click", () => {
      let test_dat: any = new Date();

      localStorage.setItem('lastActvity', test_dat)
    });

    document.onkeypress = function (e) {
      e = e || window.event;
      let test_dat: any = new Date();

      localStorage.setItem('lastActvity', test_dat)
      // use e.keyCode
    };


    return <>
      <div style={{ fontWeight: 700, fontSize: '20px', color: "black" }}> {custName} - {assetNAME}</div>

      <div style={{
        position: "absolute",
        right: "1%",

      }}>
        <Button label="Validate anomaly" icon="pi pi-check" style={{ marginRight: "12px", backgroundColor: "#dceef6", color: "#1d4651", height: "30px", fontSize: "12px", fontWeight: "500" }} onClick={(e) => handlePopBtnEdit()} />


        <Button type="button" onClick={(e) => handlePopBtn()} style={{ marginRight: "5px", height: "30px", fontSize: "11px", fontWeight: "500" }} ><i className="pi pi-plus" style={{ fontSize: "11px" }}></i><span style={{ marginLeft: "7px", }}>{t("Dashboard.CreateNewCallButton")}</span></Button>
      </div>
    </>
  }

  // 
  return <>
    <Toast ref={toast} />
    <LoadingScreen isLoading={isLoading} text="Loading..." />
    <section className="content content_background_color">
      <div className="content__header content__boxed overlapping">
        <div className="content__wrap pt-3 pb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a><i className="pli-home"></i></a></li>
              <li className="breadcrumb-item" aria-current="page"><a style={{ fontWeight: "500" }}>{t('BreadcrumAdvancedAnalytics')}</a></li>
              <li className="breadcrumb-item" aria-current="page" style={{ cursor: "pointer" }} onClick={() => {
                navigate('/dashboard')
              }}><a style={{ fontWeight: "500" }}>ESP anomaly detection</a></li>
              <li className="breadcrumb-item" aria-current="page"><a style={{
                backgroundColor: "white",
                padding: "3px",
                color: "#25476a",
                borderRadius: "6px",
                paddingRight: "8px",
                paddingLeft: "8px",
                fontWeight: "500"
              }}>Asset history</a></li>
            </ol>
          </nav>
          <p className="lead text-overflow">
            <p className="lead text-overflow" style={{ color: 'white', fontSize: "25px" }}>
              {"Asset history"}
            </p>
          </p>
          <div className="page-heading">
            <div className="row bg-primary" id="divCustFilter">
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div className="row">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content__boxed">
        <div className="content__wrap">
          <div className="card" style={{ padding: "18px" }}>
            <Header />
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            {(activeIndex == 0) &&
              <>
                <Aitrend StartDateCurrentAnomaly={StartDateCurrentAnomaly} EndDateCurrentAnomaly={EndDateCurrentAnomaly} currentAnamolyStartDate={currentAnamolyStartDate} currentAnamolyEndDate={currentAnamolyEndDate} ESPCallHistoryData={CurDataESPCallHistory} ESPAIvsHeathIndexData={CurPageAIvsHeathIndex} ESPRawTrendData={CurPageRawTrend} setChosenDay={setChosenDay} CurPageRawTrendAnamolyType={CurPageRawTrendAnamolyType} chosenDay={chosenDay} chosenDayDateRange={chosenDayDateRange} setchosenDayDateRange={setchosenDayDateRange} setzoomDateRange={setzoomDateRange} zoomDateRange={zoomDateRange}></Aitrend>
              </>

            }
            {(activeIndex == 1) &&
              <>
                <HistoryAnalyst CurPageAnalystHistory={CurPageAnalystHistory}></HistoryAnalyst>
              </>

            }
          </div>
        </div>
      </div>
    </section>
    <Dialog header={<Icon />} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
      <AnamolyVerificationAddEditPopUp title={popUpTitle} setVisible={setVisible} operatorList={showOperatorList} data={tempEditData} UpdateCustomToast={UpdateCustomToast} />
    </Dialog>
  </>

}

export default Analyze;
// React
import { useEffect, useState } from "react";
// i18n
import { useTranslation } from "react-i18next";
// react Select
import Select from 'react-select';
// prime React
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete } from 'primereact/autocomplete';
import SelectDaysFilter from './anomaly-detection-calender-filer'
import { useSelector } from "react-redux";
import { AppState } from "../../store/esp-ai/store";
import Helpers from "../../../common/utils/helpers";


const FilterComponent = ({ filterText, onFilter, onClear, setPopUpTitle, setVisible, setOperatorFilter, setSecurityFilter, operators, setTextSearchIcon, setFilterText, setChosenDay, chosenDay, setchosenDayDateRange, chosenDayDateRange, operatorFilter }: { filterText: any, onFilter: any, onClear: any, setPopUpTitle: any, setVisible: any, setOperatorFilter: any, setSecurityFilter: any, operators: any, setTextSearchIcon: any, setFilterText: any, setChosenDay: any, chosenDay: any, setchosenDayDateRange: any, chosenDayDateRange: any, operatorFilter: any }) => {
  // var setChosenDay:any = [];
  // var setchosenDayDateRange:any = [];
  // var chosenDayDateRange:any = [];

  const OperatorData_Redux: any = useSelector((state: AppState) => state.EspAnomalyDetectionStore.OperatorStoreData);
  const AnamolyDetectionData_Redux: any = useSelector((state: AppState) => state.EspAnomalyDetectionStore.AnamolyDetectionStoreData);

  const { t } = useTranslation();
  const [operatorVal, setOperatorVal] = useState<any>(OperatorData_Redux);
  const [displayCrossBtn, setdisplayCrossBtn] = useState<any>("none");
  const [displayDateFilter, setdisplayDateFilter] = useState<any>("");
  const [operatorsSelectedLabels, setoperatorsSelectedLabels] = useState(t('AllOperators'));
  const [severitySelectedLabels, setseveritySelectedLabels] = useState(t('AllSeverityLevels') as any);
  const Securityoptions: any = [
    // { value: 'All', label: <span dangerouslySetInnerHTML={{ __html: '<span class=" badge rounded bg-default p-1"> </span> All' }} /> },
    { _id: "1", value: 0, name: 'Normal', label: <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;<span class="security_lvl_bge_lvl_sub_normal badge rounded p-1" style="margin-right:5px;margin-left:-5px"> </span>Normal' }} /> },
    { _id: "2", value: 1, name: 'Level 1', label: <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;<span class="badge rounded bg-yellow p-1" style="margin-right:5px;margin-left:-5px"> </span>Level 1' }} /> },
    { _id: "4", value: 2, name: 'Level 2', label: <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;<span class="badge rounded bg-orange p-1" style="margin-right:5px;margin-left:-5px"> </span>Level 2' }} /> },
    { _id: "3", value: 3, name: 'Level 3', label: <span dangerouslySetInnerHTML={{ __html: '&nbsp;&nbsp;<span class="badge rounded bg-danger p-1" style="margin-right:5px;margin-left:-5px"> </span>Level 3' }} /> }
  ];
  const [securityVal, setSecurityVal] = useState<any>([0, 1, 2, 3]);
  const [value, setValue] = useState<any>('');
  let session = window.localStorage;
  
  useEffect(() => {
    let session_checkvalue = session.getItem("summary_filter_dates_readable");
    let session_checkvalue_array: any = session_checkvalue?.split(",");
    
    if (session.getItem("summary_filter_days") == 'A') {
      setdisplayDateFilter("Last 6 hours data")

    } else if (session.getItem("summary_filter_days") == 'M') {
      setdisplayDateFilter("Last 12 hours data")
    } else if (session.getItem("summary_filter_days") == 'P') {
      setdisplayDateFilter("Last 24 hours data")
    } else if (session.getItem("summary_filter_days") == 'R') {
      setdisplayDateFilter("Last 7 days data")
    } else if (session.getItem("summary_filter_days") == 'T') {
      setdisplayDateFilter("Last 30 days data");
    }else if(session_checkvalue_array == null || session_checkvalue_array == undefined){
      setdisplayDateFilter("")
    }else if(session_checkvalue_array[0] != session_checkvalue_array[1]){
      // 
      let session_checkvalueISO: any = session.getItem('summary_filter_dates');
      let session_checkvalueISO_array: any = session_checkvalueISO?.split(",");

      if(chosenDayDateRange != ""){
        setdisplayDateFilter(`Data from ${Helpers.convertFullDateToReadableFormatForFilterRange(chosenDayDateRange[0])} to ${Helpers.convertFullDateToReadableFormatForFilterRange(chosenDayDateRange[1])}`)
      }else{
        setdisplayDateFilter(`Data from ${Helpers.convertFullDateToReadableFormatForFilterRange(session_checkvalueISO_array[0])} to ${Helpers.convertFullDateToReadableFormatForFilterRange(session_checkvalueISO_array[1])}`)
      }
      
    }else{
      setdisplayDateFilter("");
    }

  }, [chosenDay, chosenDayDateRange,OperatorData_Redux,session.getItem("summary_filter_dates_readable")])
  useEffect(() => {
    // if(OperatorData_Redux){
    //   setFlag(true);

    setOperatorVal(OperatorData_Redux);

    // }

    // let session = window.localStorage;
    // let summary_filter_dates_readable: any = session.getItem('summary_filter_dates_readable');
    // summary_filter_dates_readable = summary_filter_dates_readable?.split(",");
    // if (summary_filter_dates_readable == undefined) {
    //   setOperatorVal(operators);
    // } else {
    //   if (summary_filter_dates_readable[0] == summary_filter_dates_readable[1]) {

    //     setOperatorVal(operators);

    //   } else {

    //     if (operatorFilter.length > 0) {
    //       // setOperatorVal([operatorFilter[operatorFilter.length - 1]]);
    //       setOperatorVal(operators);
    //     } else {
    //       // setOperatorVal([operators[0]]);
    //       console.log(operatorFilter);
    //       setOperatorVal(operators);
    //     }
    //   }
    // }



    // setSecurityVal(Securityoptions);
  }, [OperatorData_Redux])


  // useEffect(() => {
  //   let session = window.localStorage;
  //   let summary_filter_dates_readable: any = session.getItem('summary_filter_dates_readable');
  //   summary_filter_dates_readable = summary_filter_dates_readable?.split(",");
  //   if (summary_filter_dates_readable == undefined) {
  //     setOperatorVal(operators);
  //     console.log("sa");
  //   } else {
  //     if (summary_filter_dates_readable[0] != summary_filter_dates_readable[1]) {
  //       if (operatorFilter.length > 0) {
  //         // setOperatorVal([operatorFilter[operatorFilter.length - 1]]);
  //         console.log("sa1");
  //         setOperatorVal(operatorFilter);
  //       } else {

  //         // setOperatorVal([operators[0]]);
  //         console.log("sa2");
  //         setOperatorVal(operators);
  //       }
  //     }
  //   }


  // }, [operatorFilter])




  const securityTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        {/* {option.label} */}
        <div>{option.label}</div>
      </div>
    );
  };

  const handleOperatorFilter = (e: any) => {
    setOperatorVal(e.value);
    setOperatorFilter(e?.value);
    if (e.value.length == operators.length) {
      setoperatorsSelectedLabels("All operators");
    } else {
      setoperatorsSelectedLabels(`${e.value.length} ${t('OperatorsSelected')}`)
    }
    if (e?.name) {
      // setOperatorVal({ 'value': e?._id, 'name': e?.name })
      setOperatorVal(e.value)
      setOperatorFilter(e?.name)
    }
  }
  const handleSecurityFilter = (e: any) => {
    if (e?.value) {
      setSecurityFilter(e?.value)
    }
  }
  const handleTextSearchEnter = (e: any, type: any) => {

    if (type == "search") {
      if (e.target.value.length == 0) {
        setdisplayCrossBtn("none");
        setTextSearchIcon("")
        setFilterText("");
      } else {
        setTextSearchIcon(e);
        setdisplayCrossBtn("block");
      }
    } else {
      if (e.target.value.length == 0) {
        setdisplayCrossBtn("none");
        setTextSearchIcon("")
        setFilterText("");
      }
    }
  }
  const handleTextSearch = (e: any, type: any) => {
    if (type == "search") {
      setTextSearchIcon(e)
    } else {
      setTextSearchIcon("")
      setFilterText("");
      setdisplayCrossBtn("none");
    }
  }


  return <>
    <div className="col-md-12 asap filter_div" style={{ marginBottom: "20px", backgroundColor: "#f9fafb", borderRadius: "7px" }}>
      <div className="row" style={{ marginLeft: "3px", paddingTop: "5px", marginTop: "8px" }}>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="mobile_survey_details" style={{ marginBottom: '5px' }}>
            <label className="custom_common_label">{t('Dashboard.Operators')} &nbsp;&nbsp;&nbsp; </label>
          </div>
          <MultiSelect
            filter
            filterTemplate={`\u00A0\ ${t('AllOperators')}`}
            selectAll={true}
            style={{ width: "100%", borderRadius: "7px" }}
            className={'basic-single'}
            maxSelectedLabels={3}
            placeholder={`${t('AllOperators')}`}
            selectedItemsLabel={`${operatorsSelectedLabels}`}
            id="source"
            name="source"
            value={operatorVal}
            options={operators}
            optionLabel="name"
            onChange={(e) => {
              handleOperatorFilter(e);

            }}
          />
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="mobile_survey_details" style={{ marginBottom: '5px ' }}>
            <label className="custom_common_label">{t('Dashboard.SeverityLevel')} &nbsp;&nbsp;&nbsp; </label>
          </div>
          <MultiSelect
            filter
            filterTemplate={`\u00A0\ ${t('AllSeverityLevels')}`}
            value={securityVal}
            options={Securityoptions}
            onChange={(e) => {

              setSecurityVal(e.value)
              setSecurityFilter(e?.value);
              if (e.value.length == 4) {

                setseveritySelectedLabels(t('AllSeverityLevels') as any)
              } else {
                setseveritySelectedLabels("")
              }
            }}
            maxSelectedLabels={3}
            selectedItemsLabel={`${severitySelectedLabels}`}
            optionLabel="name"
            placeholder={`${t('AllSeverityLevels')}`}
            itemTemplate={securityTemplate}
            style={{ width: "100%", borderRadius: "7px" }}
            className={'basic-single'}
          />
          {/* <Select
            className={'basic-single'}
            classNamePrefix="select"
            placeholder={'All Severity level'}
            id="source"
            name="source"
            options={Securityoptions}
            onChange={(e) => handleSecurityFilter(e)}
          /> */}
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">
          <div className="mobile_survey_details" style={{ marginBottom: '5px ' }}>
            <label className="custom_common_label">{t('Dashboard.Search')} &nbsp;&nbsp;&nbsp; </label>
          </div>
          <div className="form-group has-search">
            <div className="row">
              <div className="col-md-12">
                <span className="pi pi-search form-control-feedback"></span>

                <input style={{ border: "1px solid #ced4da", height: "37px" }} type="text" className="form-control DashboardSearchAssetInput" onKeyUp={(e) => handleTextSearchEnter(e, "search")} onChange={onFilter} placeholder={`${t('SearchForAssetName')}`} name="survey_details_search" value={filterText} id="survey_details_search" autoComplete="off" />




                <span style={{
                  display: displayCrossBtn, position: "absolute", marginBottom: "11px",
                  bottom: "24px",
                  marginLeft: "19.5%"
                }} className="btn" onClick={(e) => handleTextSearch(e, "clear")} title={'Clear search text'}><i className="pi pi-times"></i></span>
              </div>
              {/* <div className="col-md-2" style={{ display: displayCrossBtn }}> */}
              {/* <button className="btn btn-primary" onClick={(e) => handleTextSearch(e, "search")} title={'Click to search'}><i className="pi pi-search"></i></button>&nbsp;&nbsp; */}
              {/* <span style={{ display: displayCrossBtn }} className="btn" onClick={(e) => handleTextSearch(e, "clear")} title={'Clear search text'}><i className="pi pi-times"></i></span> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6">

          <div className="dashboard-create-new-call-button">

            <SelectDaysFilter setChosenDay={setChosenDay} chosenDay={chosenDay} setchosenDayDateRange={setchosenDayDateRange} chosenDayDateRange={chosenDayDateRange} />

          </div>
          <div style={{ position: "absolute", right: "2%" }}>
            <>
              {displayDateFilter}
            </>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default FilterComponent;
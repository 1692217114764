import { SiteSettings } from '../../models/site-settings';
import { MenuProvider } from './context/menu-context';
import settings from '../../utils/settings.json';
import AppMenuitem from './app-menuitem';

const AppMenu = () => {
    const siteSettings = (settings as SiteSettings);

    return (
        <MenuProvider>
            <ul className="mainnav__menu nav flex-column">
                {siteSettings.menu.map((item, i) => {
                    return  <AppMenuitem item={item} root={true} index={i} key={item.title} />
                })}
            </ul>
        </MenuProvider>
    );
};

export default AppMenu;



import { t } from 'i18next';
import { Button } from 'primereact/button';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import Helpers from '../../../common/utils/helpers';
import { Tooltip } from 'primereact/tooltip';
import LoadingScreen from "../common/loading";
import AnomalyDetectionService from '../../services/anamolt-detection/anomaly-detection.services';
import fileDownload from 'js-file-download';
import { OverlayPanel } from 'primereact/overlaypanel';
import OverpanelHistoryTabComments from '../common/overpanel-history-tab-comments';

import { DataTable as PrimeDatatable, DataTableFilterMeta } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';



const HistoryAnalyst = (props: any) => {
  const [filterText, setFilterText] = useState("");
  const [filteredValues, setFilteredValues] = useState<any>([]);
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const filterDpDown1 = useRef<any>(null);
  const [displayCrossBtn, setdisplayCrossBtn] = useState<any>("none");
  const [textSearchIcon, setTextSearchIcon] = useState<any>("");


  useEffect(() => {
    setisLoading(true);
    let sorted_data = props.CurPageAnalystHistory.sort((a: any, b: any) => {
      let b_date: any = new Date(b.predictedOn);
      let a_date: any = new Date(a.predictedOn);
      return b_date - a_date;
    });
    console.log(sorted_data);
    setFilteredValues(sorted_data);
    setTimeout(() => {
      setisLoading(false);
    }, 1000)

  }, [props.CurPageAnalystHistory]);

  useEffect(() => {

    filteredItems();

  }, [filterText]);


  const sortIcon = <i className="pi pi-chevron-down" style={{ fontSize: "15px" }}></i>;
  const dataTableStyles: TableStyles = {
    table: {
      style: {
        // borderRight: "1px solid #ccc !important",
        // borderLeft: "1px solid #ccc !important",
        borderRadius: '8px',
        display: 'none'
      }
    },

    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        borderTop: "1px solid rgba(0,0,0,0.07)",
        borderLeft: "0px solid rgba(0,0,0,0.07)",
        '&:last-of-type': {
          borderRight: "0px solid rgba(0,0,0,0.07)",
        },
        justifyContent: "space-between",
        fontWeight: "bold",
        paddingRight: "5px",
        paddingBottom: "0px !important",
        textAlign: "center"
        // border: "1px solid #ccc !important"

        // width: "104% !important",
        // marginLeft: "-1%  !important"
      }
    },
    cells: {
      style: {
        borderLeft: "0px solid rgba(0,0,0,0.07)",
        '&:last-of-type': {
          borderRight: "0px solid rgba(0,0,0,0.07)",
          //borderBottom: "1px solid rgba(0,0,0,0.07)",
        },
        // border: "1px solid #ccc !important"
      }
    },
    pagination: {
      style: {
        justifyContent: "center",
        width: "90%"
      }
    }
  }
  let test_data = [{
    "_id": "6406b4234a268a100cf56239",
    "tempID": null,
    "customerID": "8ad6e679-209d-412f-86ef-8176c3dffe82",
    "thingNo": "24fc6ae0-2c06-4eae-9dcd-c5612db5b1c3",
    "cylinder": 0,
    "createdDate": null,
    "score": null,
    "message": "",
    "faultStatus": null,
    "faultProbability": null,
    "state": "close",
    "comments": "test",
    "verifiedOn": "2023-03-07T03:48:51.356Z",
    "verifiedBy": "sMeda",
    "firstOccurance": "2023-02-27T03:30:00Z",
    "lastOccurance": "2023-03-07T03:30:00Z",
    "occurredOn": null,
    "typeOfIssue": null,
    "reason": "Gaslock",
    "status": -1,
    "assetType": "",
    "aIstatus": null,
    "aiDuration": null,
    "pinAsset": false,
    "customerName": "ChampionXESPTrial",
    "userName": "Srinivas Meda",
    "assetName": "NEAL LETHCO 34-33 B UNIT 5WA",
    "queryStartTime": "",
    "queryEndTime": "",
    "dateFilterStartDate": null,
    "dateFilterEndDate": null,
    "isKeniPastCall": false,
    "leak": true,
    "active": true,
    "modelType": "Esp",
    "workOrder": "",
    "attachments": null,
    "severity": "medium",
    "reminderFreq": "",
    "sucValvesReplaced": null,
    "disValvesReplaced": null,
    "files": null,
    "assetId": [],
    "confidence": null,
    "predictedOn": "2023-02-27T03:30:00Z",
    "lastNotifiedDate": "2023-03-07T00:00:00Z",
    "userChange": null,
    "stateChange": null,
    "newCreateIssue": null,
    "actionID": ""
  }];
  function OverlayPanelComment(props: any) {
    let displayState = props.displayState;
    return <>
      <OverlayPanel style={{ display: "block" }}>
        <div>
          Comments : {props.data}
        </div>
      </OverlayPanel>
    </>
  }
  const columns: any = [
    {
      name: "Predicted date",
      width: "12%",
      selector: 'predictedOn',
      cell: (row: any) => {
        return <>
          <span title={Helpers.convertFullDateToReadableFormat(row.predictedOn)}>{Helpers.convertFullDateToReadableFormat(row.predictedOn)}</span>
        </>
      },
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      }
    }, {
      name: "AI prediction",
      selector: "faultStatus",
      cell: (row: any) => {
        if (row?.faultStatus == null) {
          return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
        } else if (row?.faultStatus == 0) {
          return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>

        } else if (row?.faultStatus == 1) {

          return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly</span>


          </>
        } else if (row?.faultStatus == -4) {
          return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
        }
      },
      sortable: true,
      width: "10%",
      style: {
        fontSize: "9px",
        fontWeight: 500
      }
    }, {
      name: <>Severity type</>,
      selector: "",
      cell: (row: any) => {
        if (row?.aiSeverity == "low" || row?.aiSeverity == "1") {
          return <><span className="badge security_lvl_bge_lvl1" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl1_sub"> </span> {t("Level")} 1</span></>
        } else if (row?.aiSeverity == "medium" || row?.aiSeverity == "2") {
          return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl2"><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span> {t("Level")} 2</span></>
        } else if (row?.aiSeverity == "high" || row?.aiSeverity == "3") {
          return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl3"><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> {t("Level")} 3</span></>
        } else if (row?.aiSeverity == "normal" || row?.aiSeverity == "Normal" || row?.aiSeverity == "0") {
          return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
        } else if (row?.aiSeverity == "shutdown" || row?.aiSeverity == "Shutdown" || row?.aiSeverity == "-4") {
          return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
        } else {
          return <>N/A</>
        }
      },
      sortable: true,
      // width: "10%",
      style: {
        fontSize: "9px",
        fontWeight: 500
      }
    }, {
      name: "Anomaly type",
      selector: "",
      cell: (row: any) => {
        if (row?.aiAnomaly == "1") {
          return <span className='gasSlugBadge'>Gas Slug</span>;
        } else if (row?.aiAnomaly == "2") {
          return <>Gas leak</>
        } else {
          if (row?.faultStatus == null) {
            return <>N/A</>

          } else if (row?.faultStatus == 0) {
            return <>N/A</>


          } else if (row?.faultStatus == 1) {

            return <>-</>

          } else if (row?.faultStatus == 2 || row?.faultStatus == -1) {
            return <>N/A</>

          }
        }
      },
      sortable: true,
      width: "10%",
      style: {
        fontSize: "9px",
        fontWeight: 500
      }
    }, {
      name: "Analyst action",
      selector: "leak",
      cell: (row: any) => {
        if (row?.leak == true) {

          let setSeverityLvl = "";
          let setSeverityLvlMsg = "";
          if (row?.severity == "low" || row?.severity == "1") {
            setSeverityLvl = "(Lvl 1)";
            setSeverityLvlMsg = "Cautionary, Informational"
          } else if (row?.severity == "medium" || row?.severity == "2") {
            setSeverityLvl = "(Lvl 2)";
            setSeverityLvlMsg = "Significant Impact/Severe Downgraded"
          } else if (row?.severity == "high" || row?.severity == "3") {
            setSeverityLvl = "(Lvl 3)";
            setSeverityLvlMsg = "Critical Impact/Potential System Shutdown"
          }

          return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly { }</span>

            {(setSeverityLvl == "asd")
              ?
              <>
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                  data-pr-tooltip={setSeverityLvlMsg}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  style={{ fontSize: '12px', cursor: 'pointer', marginLeft: "2px", marginTop: "1px" }}>
                </i>
              </>
              :
              ""
            }

          </>

        } else if (row?.leak == false) {
          return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
        } else {
          return "";
        }
      },
      sortable: true,
      width: "10%",
      style: {
        fontSize: "9px",
        fontWeight: 500
      }
    }, {
      name: "Verified severity type",
      selector: "severity",
      cell: (row: any) => {
        if (row?.leak == true) {

          let setSeverityLvl = "";
          let setSeverityLvlMsg = "";
          if (row?.severity == "low" || row?.severity == "1") {
            return <><span className="badge security_lvl_bge_lvl1" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl1_sub"> </span> {t("Level")} 1</span></>
            setSeverityLvl = "(Lvl 1)";
            // setSeverityLvlMsg = "Cautionary, Informational"
          } else if (row?.severity == "medium" || row?.severity == "2") {
            return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl2"><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span> {t("Level")} 2</span></>
            setSeverityLvl = "(Lvl 2)";
            // setSeverityLvlMsg = "Significant Impact/Severe Downgraded"
          } else if (row?.severity == "high" || row?.severity == "3") {
            return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl3"><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> {t("Level")} 3</span></>
            setSeverityLvl = "(Lvl 3)";
            // setSeverityLvlMsg = "Critical Impact/Potential System Shutdown"
          } else {
            return <>N/A</>
          }

          return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly { }</span>

            {(setSeverityLvl != "")
              ?
              <>
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                  data-pr-tooltip={setSeverityLvlMsg}
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  style={{ fontSize: '12px', cursor: 'pointer', marginLeft: "2px", marginTop: "1px" }}>
                </i>
              </>
              :
              ""
            }

          </>

        } else if (row?.leak == false) {
          return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
        } else {
          return "";
        }
      },
      sortable: true,
      width: "10%",
      style: {
        fontSize: "9px",
        fontWeight: 500
      }
    }, {
      name: "Verified anomaly type",
      selector: (row: any) => {
        if (row?.reason == null) {
          return "";
        } else {
          return row?.reason;
        }
      },
      cell: (row: any) => {
        if (row?.reason != null) {
          if (row?.reason == "CommIssue") {
            return <>Comm Issue</>
          } else if (row?.reason == "SensorError") {
            return <>Sensor Error</>
          } else if (row?.reason == "Gasslug") {
            return <span className='gasSlugBadge'>Gas Slug</span>;
          } else if (row?.reason == "Gasleak") {
            return <>Gas leak</>
          } else if (row?.reason == "Gaslock" || row?.reason == "gas_lock") {
            return <span className='gasLockBadge'>Gas lock</span>;
          } else {
            return <>
              <div>{row?.reason}</div>
            </>;
          }

        } else {
          return <>N/A</>
        }
      },
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      }
    }, {
      name: "Analyst verified On",
      width: "12%",
      selector: "verifiedOn",
      cell: (row: any) => <>
        <span title={Helpers.convertFullDateToReadableFormat(row.verifiedOn)}>{Helpers.convertFullDateToReadableFormat(row.verifiedOn)}</span>
      </>,
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      },
      omit: true
    }, {
      name: "Verified by",
      selector: (row: any) => row.userName,
      width: "10%",

      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      },
      omit: true
    }, {
      name: "Start date",
      width: "10%",
      selector: "firstOccurance",
      cell: (row: any) => <><span title={Helpers.convertFullDateToReadableFormat(row.firstOccurance)}>{Helpers.convertFullDateToReadableFormat(row.firstOccurance)}</span></>,
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500,
        textOverflow: "ellipsis"
      }
    }, {
      name: "Repair date",
      width: "12%",
      selector: (row: any) => {

        if (row.lastOccurance == null) {
          return "";
        } else {
          return row.lastOccurance;
        }
      },
      cell: (row: any) => {

        if (row.lastOccurance == null) {
          return "N/A";
        } else {
          return <>
            <span title={Helpers.convertFullDateToReadableFormat(row.lastOccurance)}>{Helpers.convertFullDateToReadableFormat(row.lastOccurance)}</span>
          </>
        }
      },
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      }
    }, {
      name: <div>Miscellaneous information</div>,
      selector: (row: any) => {
        let comments = row?.comments;

        if (comments == null) {
          comments = "N/A";
        }

        return <>
          <>
            <OverpanelHistoryTabComments data={row?.comments} username={row.userName} verifiedOn={Helpers.convertFullDateToReadableFormat(row.verifiedOn)} />
          </>
        </>

        return <>
          <span title={row?.comments}>
            {(row?.attachments != null && row?.attachments[0] != "null") ?
              <i className="pi pi-download" style={{ cursor: "pointer", marginRight: "6px", fontSize: "10px" }} onClick={(e) => {
                AnomalyDetectionService.DownloadService(row?.attachments[0]).then((res) => {
                  fileDownload(res, row?.attachments[0])

                })
              }} ></i>
              :
              ""}
            {/* onClick={(e) => {
                      AnomalyDetectionService.DownloadService(attachmentName).then((res) => {
                        fileDownload(res, attachmentName)

                      })
                    }} */}
            {row?.comments}
          </span>
        </>

      },
      sortable: true,
      style: {
        fontSize: "11px",
        fontWeight: 500
      }
    }

  ];

  const filteredItems = () => {
    let filteredAnamolyList: any = (props.CurPageAnalystHistory.length > 0) ? props.CurPageAnalystHistory : [];

    if (filterText) {
      filteredAnamolyList = filteredAnamolyList.filter((item: {
        predictedOn: any;
        faultStatus: { toString: () => string; };
        verifiedOn: { toString: () => string; };
        leak: { toString: () => string; };
        userName: { toString: () => string; };
        firstOccurance: { toString: () => string; };
        lastOccurance: { toString: () => string; };
        comments: { toString: () => string; };
        reason: { toString: () => string; };

      }) => {
        // userName
        return (Helpers.convertFullDateToReadableFormat(item.predictedOn).toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          Helpers.convertFullDateToReadableFormat(item.lastOccurance).toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          item.leak?.toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          item.comments?.toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          item.userName?.toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          item.reason?.toString().toLowerCase().includes(filterText.toLowerCase())
          ||
          item.faultStatus == filterText
          ||
          item?.verifiedOn?.toString()?.toLowerCase().includes(filterText.toLowerCase()))
      });
    }
    setFilteredValues(filteredAnamolyList);
  }

  const handleTextSearchEnter = (e: any, type: any) => {

    if (type == "search") {
      if (e.target.value.length == 0) {
        setdisplayCrossBtn("none");
        setTextSearchIcon("")
        setFilterText("");
      } else {
        setTextSearchIcon(e);
        setdisplayCrossBtn("block");
      }
    } else {
      if (e.target.value.length == 0) {
        setdisplayCrossBtn("none");
        setTextSearchIcon("")
        setFilterText("");
      }
    }
  }

  const handleTextSearch = (e: any, type: any) => {
    if (type == "search") {
      setTextSearchIcon(e)
    } else {
      setTextSearchIcon("")
      setFilterText("");
      setdisplayCrossBtn("none");
    }
  }
  const subHeaderComponentMemo = React.useMemo(() => {

    return (
      <>
  
        <input type="text" style={{ width: "22%" }} className="form-control" onChange={(e: any) => {

          setFilterText(e.target.value)
          handleTextSearchEnter(e, "search")

        }} placeholder={`${t('Dashboard.SearchPlaceholder')}`} name="survey_details_search" value={filterText} id="survey_details_search" autoComplete="off" />
        <span style={{
          display: displayCrossBtn, position: "absolute",
          bottom: "5px",
          right: "13em"
        }} className="btn" onClick={(e) => handleTextSearch(e, "clear")} title={'Clear search text'}><i className="pi pi-times"></i></span>


        <Button style={{
          marginRight: "9px", marginLeft: "5px", height: "30px", fontSize: "12px", backgroundColor: "rgb(37, 71, 106)",
          color: "white",
          borderRadius: "8px",
          fontWeight: "400"
        }} onClick={(e) => handleESPListDownload(e)}><i className='pi pi-cloud-download' />&nbsp;{'Download table'}</Button>
      </>

    );
  }, [filterText, resetPaginationToggle, filteredValues]);


  // predictedOn: any;
  //       faultStatus: { toString: () => string; };
  //       verifiedOn: { toString: () => string; };
  //       leak: { toString: () => string; };
  //       userName: { toString: () => string; };
  //       firstOccurance: { toString: () => string; };
  //       lastOccurance: { toString: () => string; };
  //       comments: { toString: () => string; };
  //       reason: { toString: () => string; };
  const handleESPListDownload = (e: any) => {

    if (filteredValues?.length > 0) {
      filteredValues?.map((val: any, i: any) => {
        if (val.predictedOn != null) {
          val.finalPredictedOn = Helpers.convertFullDateToReadableFormat(val.predictedOn);
        } else {
          val.finalPredictedOn = "";
        }

        // check AI Prediction 
        if (val?.faultStatus == null) {
          val.finalAIPrediction = "Unknown";
        } else if (val?.faultStatus == 0) {
          val.finalAIPrediction = "Normal";

        } else if (val?.faultStatus == 1) {

          val.finalAIPrediction = "Anomaly";


        } else if (val?.faultStatus == -4) {
          val.finalAIPrediction = "Shutdown";
        }


        // Severity type

        if (val?.aiSeverity == "low" || val?.aiSeverity == "1") {
          val.FinalaiSeverity = "Level 1";
        } else if (val?.aiSeverity == "medium" || val?.aiSeverity == "2") {
          val.FinalaiSeverity = "Level 2";
        } else if (val?.aiSeverity == "high" || val?.aiSeverity == "3") {
          val.FinalaiSeverity = "Level 3";
        } else if (val?.aiSeverity == "normal" || val?.aiSeverity == "Normal" || val?.aiSeverity == "0") {
          val.FinalaiSeverity = "Normal";
        } else if (val?.aiSeverity == "shutdown" || val?.aiSeverity == "Shutdown" || val?.aiSeverity == "-4") {
          val.FinalaiSeverity = "Shutdown";
        } else {
          val.FinalaiSeverity = "";
        }

        // check anomaly type

        if (val?.aiAnomaly == "1") {
          val.FinalaiAnomaly = "Gas Slug";
        } else if (val?.aiAnomaly == "2") {
          val.FinalaiAnomaly = "Gas leak";
        } else {
          val.FinalaiAnomaly = "";
        }


        // check verified on 
        if (val.verifiedOn != null) {
          val.finalVerifiedOn = Helpers.convertFullDateToReadableFormat(val.verifiedOn);

        } else {
          val.finalVerifiedOn = "";
        }


        // check analyst action start
        let setSeverityLvl = "";
        if (val?.severity == "low" || val?.severity == "1") {
          val.setSeverityLvl = "Level 1";

        } else if (val?.severity == "medium" || val?.severity == "2") {

          val.setSeverityLvl = "Level 2";

        } else if (val?.severity == "high" || val?.severity == "3") {

          val.setSeverityLvl = "Level 3";

        }



        if (val?.leak == true) {

          let setSeverityLvlMsg = "";
          if (val?.severity == "low") {
            setSeverityLvl = "Level 1";
            setSeverityLvlMsg = "Level 1";

          } else if (val?.severity == "medium") {
            setSeverityLvl = "Level 2";
            setSeverityLvlMsg = "Level 2";

          } else if (val?.severity == "high") {
            setSeverityLvl = "Level 3";
            setSeverityLvlMsg = "Level 3";

          }

          val.finalAnalystAction = "Anomaly ";


        } else if (val?.leak == false) {
          val.finalAnalystAction = "Normal";
          val.setSeverityLvl = "Normal";

        } else {
          val.finalAnalystAction = "";

        }
        // check analyst action end
        if (val.firstOccurance != null) {
          val.finalfirstOccurance = Helpers.convertFullDateToReadableFormat(val.firstOccurance);
        } else {
          val.finalfirstOccurance = "";
        }

        if (val.lastOccurance != null) {
          val.finallastOccurance = Helpers.convertFullDateToReadableFormat(val.lastOccurance);
        } else {
          val.finallastOccurance = "";
        }

      })


      Helpers.exportToCSV(
        filteredValues,
        "ESP_Analysts_History",
        'ESP_Analysts_History',
        {
          finalPredictedOn: 'Predicted date',
          finalAIPrediction: 'AI Prediction',
          FinalaiSeverity: 'Severity type',
          FinalaiAnomaly: 'Anomaly type',
          finalAnalystAction: 'Analyst Action',
          setSeverityLvl: 'Verified severity type',
          reason: 'Verified anomaly type',
          finalfirstOccurance: 'Start Date',
          finallastOccurance: 'Repair/Close Date',
          finalVerifiedOn: 'Analyst Verified On',
          userName: 'Verified By',
          comments: 'Comments',
        },
        ["finalPredictedOn", "finalAIPrediction", "FinalaiSeverity", "FinalaiAnomaly", "finalAnalystAction", "setSeverityLvl", "reason", "finalfirstOccurance", "finallastOccurance", "comments", "userName", "finalVerifiedOn"]);
    }
  }
  return (
    <><LoadingScreen isLoading={isLoading} text="Loading..." /><div style={{ marginTop: "1%" }}>


      <DataTable
        columns={columns}
        data={filteredValues}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        // defaultSortFieldId={1}
        defaultSortAsc={false}
        customStyles={dataTableStyles}
        responsive={true}
        striped={false}
        highlightOnHover={false}
        sortIcon={sortIcon} />


      <div className="card" style={{ border: "1px solid rgb(196, 196, 196)" }}>
        <PrimeDatatable value={filteredValues} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 40, 50]} currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink">

          {/* Predicted date */}
          <Column style={{ borderTopLeftRadius: "8px" }} sortable showFilterMatchModes={false} field="predictedOn" body={(body_val) => {
            return <>
              <span title={Helpers.convertFullDateToReadableFormat(body_val.predictedOn)}>{Helpers.convertFullDateToReadableFormat(body_val.predictedOn)}</span>
            </>
          }} header={`Predicted date`}></Column>

          {/* AI prediction*/}
          <Column style={{ borderTopLeftRadius: "8px" }} sortable field="faultStatus" body={(body_val) => {
            if (body_val?.faultStatus == null) {
              return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span> {t('Unknown')}</span></>
            } else if (body_val?.faultStatus == 0) {
              return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>

            } else if (body_val?.faultStatus == 1) {

              return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly</span>


              </>
            } else if (body_val?.faultStatus == -4) {
              return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
            }
          }} header={`AI prediction`}></Column>

          {/* Severity type */}
          <Column sortable field="aiSeverity" body={(body_val) => {
            if (body_val?.aiSeverity == "low" || body_val?.aiSeverity == "1") {
              return <><span className="badge security_lvl_bge_lvl1" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl1_sub"> </span> {t("Level")} 1</span></>
            } else if (body_val?.aiSeverity == "medium" || body_val?.aiSeverity == "2") {
              return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl2"><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span> {t("Level")} 2</span></>
            } else if (body_val?.aiSeverity == "high" || body_val?.aiSeverity == "3") {
              return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl3"><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> {t("Level")} 3</span></>
            } else if (body_val?.aiSeverity == "normal" || body_val?.aiSeverity == "Normal" || body_val?.aiSeverity == "0") {
              return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
            } else if (body_val?.aiSeverity == "shutdown" || body_val?.aiSeverity == "Shutdown" || body_val?.aiSeverity == "-4") {
              return <><span className="badge security_lvl_bge_lvl_nil" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_nil"> </span>  {t("ShutDown")}</span></>
            } else {
              return <>N/A</>
            }
          }} header={'Severity type'}></Column>

          {/* Anomaly type */}

          <Column sortable field="aiAnomaly" body={(body_val) => {
            if (body_val?.aiAnomaly == "2") {
              return <span className='gasSlugBadge'>Gas Slug</span>;
            } else if (body_val?.aiAnomaly == "1") {
              return <>Gas lock</>
            } else {
              if (body_val?.faultStatus == null) {
                return <>N/A</>

              } else if (body_val?.faultStatus == 0) {
                return <>N/A</>


              } else if (body_val?.faultStatus == 1) {

                return <>-</>

              } else if (body_val?.faultStatus == 2 || body_val?.faultStatus == -1) {
                return <>N/A</>

              }
            }
          }} header={'Anomaly type'}></Column>

          {/* Analyst action */}

          <Column sortable field="leak" body={(body_val) => {
            if (body_val?.leak == true) {

              let setSeverityLvl = "";
              let setSeverityLvlMsg = "";
              if (body_val?.severity == "low" || body_val?.severity == "1") {
                setSeverityLvl = "(Lvl 1)";
                setSeverityLvlMsg = "Cautionary, Informational"
              } else if (body_val?.severity == "medium" || body_val?.severity == "2") {
                setSeverityLvl = "(Lvl 2)";
                setSeverityLvlMsg = "Significant Impact/Severe Downgraded"
              } else if (body_val?.severity == "high" || body_val?.severity == "3") {
                setSeverityLvl = "(Lvl 3)";
                setSeverityLvlMsg = "Critical Impact/Potential System Shutdown"
              }

              return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly { }</span>

                {(setSeverityLvl == "asd")
                  ?
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                      data-pr-tooltip={setSeverityLvlMsg}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: '12px', cursor: 'pointer', marginLeft: "2px", marginTop: "1px" }}>
                    </i>
                  </>
                  :
                  ""
                }

              </>

            } else if (body_val?.leak == false) {
              return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
            } else {
              return "";
            }
          }} header={'Analyst action'}></Column>

          {/* Verified severity type */}
          <Column sortable field="severity" body={(body_val) => {
            if (body_val?.leak == true) {

              let setSeverityLvl = "";
              let setSeverityLvlMsg = "";
              if (body_val?.severity == "low" || body_val?.severity == "1") {
                return <><span className="badge security_lvl_bge_lvl1" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl1_sub"> </span> {t("Level")} 1</span></>
                setSeverityLvl = "(Lvl 1)";
                // setSeverityLvlMsg = "Cautionary, Informational"
              } else if (body_val?.severity == "medium" || body_val?.severity == "2") {
                return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl2"><span className="badge rounded p-1 security_lvl_bge_lvl2_sub"> </span> {t("Level")} 2</span></>
                setSeverityLvl = "(Lvl 2)";
                // setSeverityLvlMsg = "Significant Impact/Severe Downgraded"
              } else if (body_val?.severity == "high" || body_val?.severity == "3") {
                return <><span style={{ fontSize: "9px" }} className="badge security_lvl_bge_lvl3"><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> {t("Level")} 3</span></>
                setSeverityLvl = "(Lvl 3)";
                // setSeverityLvlMsg = "Critical Impact/Potential System Shutdown"
              } else {
                return <>N/A</>
              }

              return <><span className="badge security_lvl_bge_lvl3" style={{ fontSize: "9px" }}><span className="badge rounded bg-danger p-1 security_lvl_bge_lvl3_sub"> </span> Anomaly { }</span>

                {(setSeverityLvl != "")
                  ?
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <i className="custom-target-icon pi pi-info-circle p-text-secondary p-overlay-badge"
                      data-pr-tooltip={setSeverityLvlMsg}
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ fontSize: '12px', cursor: 'pointer', marginLeft: "2px", marginTop: "1px" }}>
                    </i>
                  </>
                  :
                  ""
                }

              </>

            } else if (body_val?.leak == false) {
              return <><span className="badge security_lvl_bge_lvl_normal" style={{ fontSize: "9px" }}><span className="badge rounded p-1 security_lvl_bge_lvl_sub_normal"> </span>  {t("Normal")}</span></>
            } else {
              return "";
            }
          }} header={'Verified severity type'}></Column>

          {/* <Column sortable field="severity" body={(body_val) => {
  return <>{body_val?.customerName}</>
}} header={'Verified severity type'}></Column> */}

          {/*Verified Anomaly type */}
          <Column sortable field="reason" body={(body_val) => {
            if (body_val?.reason != null) {
              if (body_val?.reason == "CommIssue") {
                return <>Comm Issue</>
              } else if (body_val?.reason == "SensorError") {
                return <>Sensor Error</>
              } else if (body_val?.reason == "Gasslug") {
                return <span className='gasSlugBadge' style={{ float: "none" }}>Gas Slug</span>;
              } else if (body_val?.reason == "Gasleak") {
                return <>Gas leak</>
              } else if (body_val?.reason == "Gaslock" || body_val?.reason == "gas_lock") {
                return <span className='gasLockBadge' style={{ float: "none" }}>Gas lock</span>;
              } else {
                return <>
                  <div>{body_val?.reason}</div>
                </>;
              }

            } else {
              return <>N/A</>
            }
          }} header={'Verified anomaly type'} style={{ textAlign: 'center' }}></Column>

          {/*Start date */}
          <Column sortable field="firstOccurance" body={(body_val) => {

            return <><span title={Helpers.convertFullDateToReadableFormat(body_val.firstOccurance)}>{Helpers.convertFullDateToReadableFormat(body_val.firstOccurance)}</span></>
          }} header={'Start date'}></Column>

          {/*Repair date */}
          <Column sortable field="lastOccurance" body={(body_val) => {
            if (body_val.lastOccurance == null) {
              return "N/A";
            } else {
              return <>
                <span title={Helpers.convertFullDateToReadableFormat(body_val.lastOccurance)}>{Helpers.convertFullDateToReadableFormat(body_val.lastOccurance)}</span>
              </>
            }
          }} header={'Repair date'}></Column>





          {/* Miscellaneous information */}
          <Column field="" body={(body_val) => {
            let comments = body_val?.comments;

            if (comments == null) {
              comments = "N/A";
            }

            return <>
              <>
                <OverpanelHistoryTabComments data={body_val?.comments} username={body_val.userName} verifiedOn={Helpers.convertFullDateToReadableFormat(body_val.verifiedOn)} />
              </>
            </>
          }} header={'Miscellaneous information'} style={{ borderTopRightRadius: "8px", textAlign: "center" }}></Column>



        </PrimeDatatable>
      </div>


    </div>


    </>
  )
}

export default HistoryAnalyst